(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('AppV3Controller', AppV3Controller);

    AppV3Controller.$inject = ['$scope', '$state', '$localStorage', '$sessionStorage'];

    function AppV3Controller ($scope, $state, $localStorage, $sessionStorage) {
        var vm = this;

        vm.postMessage = postMessage;

        function postMessage () {
            var token = $localStorage.authenticationToken || $sessionStorage.authenticationToken;
            console.log('posting msg to frame')
            var iframeWin = document.getElementById("da-iframe").contentWindow;
            iframeWin.postMessage(token, "*");
        }

        function init () {
            postMessage();
        }

        init();

    }
})();
