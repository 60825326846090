(function() {
    'use strict';
    angular
        .module('fleetApp')
        .factory('Person', Person);

    Person.$inject = ['$resource'];

    function Person ($resource) {
        var resourceUrl =  'api/person/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'saveAndUpdateTasks': { method: 'POST', url: 'api/person/saveAndUpdateTasks'},
            'geojson': { method:'GET', url:'api/person/geojson', isArray: true},
            'findByName': {
                method: 'GET',
                url: 'api/person/findbyname',
                isArray: true,
                transformRequest: function (data) {
                    return angular.toJson(data);
                }
            }
        });
    }
})();
