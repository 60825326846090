(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('FleetMediaDetailController', FleetMediaDetailController);

    FleetMediaDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'FleetMedia', 'User', 'Task'];

    function FleetMediaDetailController($scope, $rootScope, $stateParams, entity, FleetMedia, User, Task) {
        var vm = this;

        vm.fleetMedia = entity;

        var unsubscribe = $rootScope.$on('fleetApp:fleetMediaUpdate', function(event, result) {
            vm.fleetMedia = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
