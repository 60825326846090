(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('VehiclePaymentItemDialogController', VehiclePaymentItemDialogController);

    VehiclePaymentItemDialogController.$inject = ['$timeout', '$scope', '$rootScope', '$stateParams', '$uibModalInstance', 'entity', 'VehiclePayment',
    'vehiclePayment', '$filter'];

    function VehiclePaymentItemDialogController ($timeout, $scope, $rootScope, $stateParams, $uibModalInstance, entity, VehiclePayment,
    vehiclePayment, $filter) {
        var vm = this;

        vm.vehiclePaymentItem = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.vehiclePayment = vehiclePayment;

        vm.minStartTime = moment(vm.vehiclePaymentItem.startTime).startOf('day').toDate();
        vm.maxStartTime = moment(vm.vehiclePaymentItem.startTime).endOf('day').toDate();


        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        vm.isInitialTimeIsAfterEndTime = function() {
            var ini = moment(vm.vehiclePaymentItem.startTime);
            var end = moment(vm.vehiclePaymentItem.endTime);
            return ini.isAfter(end);
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.vehiclePaymentItem.id !== null) {
                var foundItem = $filter('filter')(vm.vehiclePayment.itens, { id: vm.vehiclePaymentItem.id }, true)[0];
                var index = vm.vehiclePayment.itens.indexOf(foundItem);
                vm.vehiclePayment.itens.splice(index, 1);
                vm.vehiclePayment.itens.push(vm.vehiclePaymentItem);
                VehiclePayment.update(vm.vehiclePayment, onSaveSuccess, onSaveError);
            } else {
                vm.vehiclePayment.itens.push(vm.vehiclePaymentItem);
                VehiclePayment.update(vm.vehiclePayment, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('fleetApp:vehiclePaymentItemUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.paymentDate = false;
        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        // ****************************************************

        vm.updateOdometer = function () {
            vm.vehiclePaymentItem.kmTotal = vm.vehiclePaymentItem.endOdometer - vm.vehiclePaymentItem.startOdometer;
            var extra = vm.vehiclePaymentItem.kmTotal - vm.vehiclePayment.kmAllowed;
            vm.vehiclePaymentItem.kmExtra = extra > 0 ? extra : 0;
            vm.vehiclePaymentItem.kmCostTotalRegular = 0;
            vm.vehiclePaymentItem.kmCostTotalTrip = 0;
            vm.vehiclePaymentItem.kmExtraRegular = 0;
            vm.vehiclePaymentItem.kmExtraTrip = 0;

            if (vm.vehiclePaymentItem.kmExtra > 0) {
                vm.vehiclePaymentItem.kmExtraRegular = vm.vehiclePaymentItem.kmExtra;
                vm.updateKmExtraRegular();
            }
        }

        vm.updateKmExtraRegular = function () {
            vm.vehiclePaymentItem.kmCostTotalRegular = vm.vehiclePaymentItem.kmExtraRegular * vm.vehiclePayment.kmCost;
        }
        vm.updateKmExtraTrip = function () {
            vm.vehiclePaymentItem.kmCostTotalTrip = vm.vehiclePaymentItem.kmExtraTrip * vm.vehiclePayment.kmCostTrip;
        }

        // ****************************************************

        vm.changeTime = function () {
            var start = vm.vehiclePaymentItem.startTime ? moment(vm.vehiclePaymentItem.startTime) : undefined;
            var end = vm.vehiclePaymentItem.endTime ? moment(vm.vehiclePaymentItem.endTime) : undefined;

            if (start && end) {
                var duration = moment.duration(end.diff(start));
                vm.vehiclePaymentItem.totalTime = duration.asMinutes();
            } else {
                vm.vehiclePaymentItem.totalTime = undefined;
            }
            extraTimeCalculate();
            vm.formatTime();
        }

        vm.formatTime = function() {
            vm.totalTimeFormatted = formatTime(vm.vehiclePaymentItem.totalTime);
            vm.extraTimeFormatted = formatTime(vm.vehiclePaymentItem.extraTime);
        }

        function extraTimeCalculate() {
            if (parseInt(vm.vehiclePaymentItem.totalTime) > vm.vehiclePayment.timeAllowed) {
                vm.vehiclePaymentItem.extraTime = vm.vehiclePaymentItem.totalTime - vm.vehiclePayment.timeAllowed;
                vm.vehiclePaymentItem.extraTimeCostTotal = (vm.vehiclePayment.extraTimeCost / 60) * vm.vehiclePaymentItem.extraTime;
            } else {
                vm.vehiclePaymentItem.extraTime = 0;
                vm.vehiclePaymentItem.extraTimeCostTotal = 0;
            }
        }

        function formatTime(value) {
            if (value) {
                return moment().startOf('day').add(value, 'minutes').format('HH:mm');
            } else {
                return undefined;
            }
        }

        vm.formatTime();

        // ****************************************************

        vm.changeTotal = function() {
            vm.vehiclePaymentItem.total = vm.vehiclePaymentItem.extraTimeCostTotal + vm.vehiclePaymentItem.kmCostTotalRegular +
                            vm.vehiclePaymentItem.kmCostTotalTrip + vm.vehiclePaymentItem.dailyCost;
        }

        // **********************************************

        vm.addExpense = function() {
            if (!vm.vehiclePaymentItem.expenses) vm.vehiclePaymentItem.expenses = [];
            var nextDate = moment(vm.vehiclePaymentItem.paymentDate).startOf('day').toDate();
            vm.vehiclePaymentItem.expenses.push({expenseDate: nextDate});
        }

        vm.addRefund = function() {
            if (!vm.vehiclePaymentItem.refunds) vm.vehiclePaymentItem.refunds = [];
            vm.vehiclePaymentItem.refunds.push({});
        }

        vm.deleteItem = function(collection, item) {
            var index = collection.indexOf(item);
            collection.splice(index, 1);
        }
    }
})();
