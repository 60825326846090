(function() {
    'use strict';
    angular
        .module('fleetApp')
        .factory('Report', Report);

    Report.$inject = ['$resource', 'DateUtils'];

    function Report ($resource, DateUtils) {
        var resourceUrl =  'api/reports/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'analytic': { method: 'POST', isArray: true, url: 'api/report/analytics',
                 transformRequest: function (data) {
                     data.startDate = DateUtils.convertLocalDateToServer(data.startDate);
                     data.endDate = DateUtils.convertLocalDateToServer(data.endDate);
                     return angular.toJson(data);
                 },
                 transformResponse: function (data) {
                     if (data) {
                         data = angular.fromJson(data);
                         angular.forEach(data, function(item) {
//                            item.firstTimeIn = DateUtils.convertDateTimeFromServer(item.firstTimeIn);
//                            item.lastTimeOut = DateUtils.convertDateTimeFromServer(item.lastTimeOut);
//                            item.durationTime = DateUtils.convertMinutesToDisplay(item.durationTime);
                         });
                     }
                     return data;
                 }
             }
        });
    }
})();
