(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('ItineraryDetailController', ItineraryDetailController);

    ItineraryDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Itinerary', 'Vehicle', 'User', 'Task', 'Company'];

    function ItineraryDetailController($scope, $rootScope, $stateParams, entity, Itinerary, Vehicle, User, Task, Company) {
        var vm = this;

        vm.itinerary = entity;

        var unsubscribe = $rootScope.$on('fleetApp:itineraryUpdate', function(event, result) {
            vm.itinerary = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
