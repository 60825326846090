(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('PositionDetailController', PositionDetailController);

    PositionDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Position', 'Vehicle'];

    function PositionDetailController($scope, $rootScope, $stateParams, entity, Position, Vehicle) {
        var vm = this;

        vm.position = entity;

        var unsubscribe = $rootScope.$on('fleetApp:positionUpdate', function(event, result) {
            vm.position = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
