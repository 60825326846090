(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('OfficeHourDashboardController', OfficeHourDashboardController);

    OfficeHourDashboardController.$inject = ['$scope', '$uibModalInstance', 'entity', '$state', '$stateParams', '$uibModalStack', '$uibModal'];

    function OfficeHourDashboardController ($scope, $uibModalInstance, entity, $state, $stateParams, $modalStack, $uibModal) {
        var vm = this;

        vm.officehours = entity.entries;
        vm.vehicleId = entity.vehicleId;
        vm.vehiclePlate = entity.vehiclePlate;
        vm.driverName = entity.driverName;
        vm.driverId = entity.driverId;

        vm.clear = clear;
        vm.edit = edit;
        vm.del = del;
        vm.add = add;

        function clear () {
            $uibModalInstance.close();
            $uibModalInstance.dismiss('cancel');
            $modalStack.dismissAll('cancel'); // force close duplicated modal
        }

        function edit (id) {
            $state.go('dashboard.officehours-modal.edit', {id: id});
        }

        function del (id) {
            $uibModal.open({
                templateUrl: 'app/entities/officehours/officehours-delete-dialog.html',
                controller: 'OfficehoursDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: ['Officehours', function(Officehours) {
                        return Officehours.get({id: id}).$promise;
                    }]
                }
            }).result.then(function() {
                clear();
            }, function() {
                clear();
            });
        }

        function add () {
            $uibModal.open({
                templateUrl: 'app/entities/officehours/officehours-dialog.html',
                controller: 'OfficehoursDialogController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: function () {
                        return {
                            startTime: $stateParams.date,
                            endTime: $stateParams.date,
                            creation: new Date(),
                            id: null,
                            vehicleId: vm.vehicleId,
                            vehiclePlate: vm.vehiclePlate,
                            driverId: vm.driverId,
                            driverFirstName: vm.driverName
                        };
                    }
                }
            }).result.then(function() {
                clear();
            }, function() {
                clear();
            });
        }

    }
})();
