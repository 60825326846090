(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('VehiclePaymentItemDeleteController',VehiclePaymentItemDeleteController);

    VehiclePaymentItemDeleteController.$inject = ['$uibModalInstance', 'vehiclePaymentItem', 'vehiclePayment', 'VehiclePayment'];

    function VehiclePaymentItemDeleteController($uibModalInstance, vehiclePaymentItem, vehiclePayment, VehiclePayment) {
        var vm = this;

        vm.vehiclePaymentItem = vehiclePaymentItem;
        vm.vehiclePayment = vehiclePayment;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            VehiclePayment.deleteItem({itemId: id, vehiclePaymentId: vm.vehiclePayment.id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
