(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('PaymentReportController', PaymentReportController);

    PaymentReportController.$inject = ['$scope', '$state', '$http', 'AlertService', 'DateUtils'];

    function PaymentReportController ($scope, $state, $http, AlertService, DateUtils) {
        var vm = this;

        vm.fortnight = function (status) {
            var dt = moment().startOf('month');
            if (status === 'first') {
                vm.startDate = dt.toDate();
                vm.endDate = dt.clone().add(14, 'd').toDate();
            } else {
                vm.startDate = dt.clone().add(15, 'd').toDate();
                vm.endDate = dt.clone().endOf('month').toDate();
            }
        }

        vm.fortnight('first');

        function onError(error) {
            AlertService.error(error.data.message);
        }

        vm.analyticReport = function(id) {
            var startDate = DateUtils.convertLocalDateToServer(vm.startDate);
            var endDate = DateUtils.convertLocalDateToServer(vm.endDate);

            var dto = {startDate: startDate, endDate: endDate, vehicleId: vm.vehicleId };
            $http.post('api/vehicle-payments/analyticReport/', dto, { responseType: 'arraybuffer' }).then(function (response) {
                var blob = new Blob([response.data], {type: "application/vnd.ms-excel"});
                saveAs(blob, "ReportAnalytics.xls");
            }, onError);
        }

    }
})();
