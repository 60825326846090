(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('VehiclePaymentDetailController', VehiclePaymentDetailController);

    VehiclePaymentDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'VehiclePayment', 'User', 'Vehicle'];

    function VehiclePaymentDetailController($scope, $rootScope, $stateParams, entity, VehiclePayment, User, Vehicle) {
        var vm = this;

        vm.vehiclePayment = entity;

        var unsubscribe = $rootScope.$on('fleetApp:vehiclePaymentUpdate', function(event, result) {
            vm.vehiclePayment = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
