(function() {
    'use strict';

    var vehicleEventHistoryFilter = {
        templateUrl: 'app/home/vehicle-event-history-filter.html',
        controller: VehicleEventHistoryFilterController,
    };

    angular
        .module('fleetApp')
        // This component can be used like: <vehicle-event-history-filter></vehicle-event-history-filter>
        .component('vehicleEventHistoryFilter', vehicleEventHistoryFilter);

    VehicleEventHistoryFilterController.$inject = ['$rootScope', '$scope', 'GeoMap', 'Position', 'DateUtils', 'AlertService', '$http'];

    function VehicleEventHistoryFilterController($rootScope, $scope, GeoMap, Position, DateUtils, AlertService, $http) {

        var vm = this;
        vm.initialDateTime = new Date();
        vm.endDateTime = new Date();
        vm.vehicle = angular.copy(GeoMap.vehicleHistory);

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        $rootScope.$broadcast("invalidateMapSize");

        $scope.$watch(
          function() { return GeoMap.vehicleHistory; },
          function(newVal, oldVal) {
            vm.vehicle = angular.copy(newVal);
          },
          true
        );

        vm.search = function() {
            Position.history({vehicleid: vm.vehicle.vehicleId, initialDateTime: vm.initialDateTime, endDateTime: vm.endDateTime},
            function(data){
                $rootScope.$broadcast("clearMap");
                $rootScope.$broadcast("showPositions", {positions: data, fitBounds: true});
            }, onError);
        };

        vm.export = function() {
            var startDate = DateUtils.convertLocalDateTimeToServer(vm.initialDateTime);
            var endDate = DateUtils.convertLocalDateTimeToServer(vm.endDateTime);
            var dto = {vehicleId: vm.vehicle.vehicleId, initialDateTime: vm.initialDateTime, endDateTime: vm.endDateTime };
            $http.post('api/positions/history-report', dto, { responseType: 'arraybuffer' }).then(function (response) {
                var blob = new Blob([response.data], {type: "application/vnd.ms-excel"});
                saveAs(blob, "positionHistory.xls");
            }, onError);
        }

        vm.close = function () {
            GeoMap.vehicleHistory = null;
            $rootScope.$broadcast("clearMap");
            $rootScope.$broadcast("invalidateMapSize");
        }

        // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

        function onError(error) {
            AlertService.error(error.data.message);
        }

    }
})();
