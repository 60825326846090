(function() {
    'use strict';

    angular
        .module('fleetApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('vehicle-payment', {
            parent: 'entity',
            url: '/vehicle-payment?page&sort&search&start&end&plate$vehicleId',
            data: {
                authorities: ['EDIT_FINANCIAL', 'ROLE_ADMIN'],
                pageTitle: 'fleetApp.vehiclePayment.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/vehicle-payment/vehicle-payments.html',
                    controller: 'VehiclePaymentController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null,
                start: null,
                end: null,
                vehicleId: null,
                plate: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', '$filter', function ($stateParams, PaginationUtil, $filter) {
                    var dt = moment().startOf('month');
                    var iniDate = dt.clone().toDate();
                    var endDate = dt.clone().add(14, 'd').toDate();
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                        start: $stateParams.start == null ? $filter('date')(iniDate, 'yyyy-MM-dd') : $stateParams.start,
                        end: $stateParams.end == null ? $filter('date')(endDate, "yyyy-MM-dd") : $stateParams.end,
                        vehicleId: $stateParams.vehicleId,
                        plate: $stateParams.plate
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('vehiclePayment');
                    $translatePartialLoader.addPart('vehiclePaymentItem');
                    $translatePartialLoader.addPart('vehicle');
                    return $translate.refresh();
                }]
            }
        })
        .state('vehicle-payment-detail', {
            parent: 'entity',
            url: '/vehicle-payment/{id}',
            data: {
                authorities: ['EDIT_FINANCIAL', 'ROLE_ADMIN'],
                pageTitle: 'fleetApp.vehiclePayment.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/vehicle-payment/vehicle-payment-detail.html',
                    controller: 'VehiclePaymentDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('vehiclePayment');
                    $translatePartialLoader.addPart('vehiclePaymentItem');
                    $translatePartialLoader.addPart('vehicle');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'VehiclePayment', function($stateParams, VehiclePayment) {
                    return VehiclePayment.get({id : $stateParams.id}).$promise;
                }]
            }
        })
        .state('vehicle-payment.new', {
            parent: 'vehicle-payment',
            url: '/new',
            data: {
                authorities: ['EDIT_FINANCIAL', 'ROLE_ADMIN'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/vehicle-payment/vehicle-payment-new-dialog.html',
                    controller: 'VehiclePaymentNewDialogController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: {
                                creation: null,
                                kmCost: null,
                                dailyCost: null,
                                extraTimeCost: null,
                                kmCostTrip: null,
                                startDate: moment().startOf('month').toDate(),
                                endDate: moment().startOf('month').add(14, 'd').toDate(),
                                expenseCost: 0,
                                refundCost: 0,
                                total: null,
                                timeAllowed: null,
                                kmAllowed: null,
                                vehicleId: null,
                                vehiclePlate: null,
                                importItineraryData: true,
                                id: null,
                                itens: [],
                                kmTotalExtra: 0,
                                kmTotalRegular: 0
                            }
                    }
                }).result.then(function() {
                    $state.go('vehicle-payment', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('vehicle-payment.edit', {
            parent: 'vehicle-payment',
            url: '/{id}/edit',
            data: {
                authorities: ['EDIT_FINANCIAL', 'ROLE_ADMIN'],
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/vehicle-payment/vehicle-payment-edit.html',
                    controller: 'VehiclePaymentEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('vehiclePayment');
                    $translatePartialLoader.addPart('vehiclePaymentItem');
                    $translatePartialLoader.addPart('vehicle');
                    $translatePartialLoader.addPart('expense');
                    $translatePartialLoader.addPart('expenseType');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'VehiclePayment', function($stateParams, VehiclePayment) {
                    return VehiclePayment.get({id : $stateParams.id}).$promise;
                }]
            }
        })
        .state('vehicle-payment.delete', {
            parent: 'vehicle-payment',
            url: '/{id}/delete',
            data: {
                authorities: ['EDIT_FINANCIAL', 'ROLE_ADMIN'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/vehicle-payment/vehicle-payment-delete-dialog.html',
                    controller: 'VehiclePaymentDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['VehiclePayment', function(VehiclePayment) {
                            return VehiclePayment.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('vehicle-payment', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });


    }

})();
