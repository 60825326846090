(function() {
    "use strict";

    angular
        .module("fleetApp")
        .controller("TaskDialogDetailController", TaskDialogController);

    TaskDialogController.$inject = [
        "ImageService",
        "$scope",
        "$stateParams",
        "$uibModalInstance",
        "entity"
    ];

    function TaskDialogController(
        ImageService,
        $scope,
        $stateParams,
        $uibModalInstance,
        entity
    ) {
        var vm = this;

        vm.clear = clear;
        vm.task = entity;
        vm.radioModel = "product";

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }

        vm.arrayToString = function(string) {
            return string.join(", ");
        };

        vm.getClass = function(invoiceItem) {
            invoiceItem.refusedReasonId === undefined ? "bold" : "bold";
        };

        vm.url = function(value, append) {
            var url = value.replace(/\"/g, "");
            return url.replace("/upload", "/upload" + append);
        };

        vm.task.photoUrl = ImageService.image300(vm.task.photoUrl);

        vm.task.fleetMedias.forEach(function(v) {
            v.urlFixed = vm.url(v.url, "/w_300,h_300/dpr_1.0");
            v.urlOriginal = vm.url(v.url, "");
        });
    }
})();
