(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('RouteReviewDetailController', RouteReviewDetailController);

    RouteReviewDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'RouteReview', 'Visit', 'User'];

    function RouteReviewDetailController($scope, $rootScope, $stateParams, entity, RouteReview, Visit, User) {
        var vm = this;

        vm.routeReview = entity;

        var unsubscribe = $rootScope.$on('fleetApp:routeReviewUpdate', function(event, result) {
            vm.routeReview = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
