(function() {
    'use strict';

    angular
        .module('fleetApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('itinerary', {
            parent: 'entity',
            url: '/itinerary?page&sort&search&date',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'fleetApp.itinerary.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/itinerary/itineraries.html',
                    controller: 'ItineraryController',
                    controllerAs: 'vm'
                }
            },
            params:  {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null,
                    date: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', '$filter', function ($stateParams, PaginationUtil, $filter) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                        date: $stateParams.date == null ? $filter('date')(new Date(), "yyyy-MM-dd") : $stateParams.date
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('itinerary');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('itinerary-detail', {
            parent: 'entity',
            url: '/itinerary/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'fleetApp.itinerary.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/itinerary/itinerary-detail.html',
                    controller: 'ItineraryDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('itinerary');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Itinerary', function($stateParams, Itinerary) {
                    return Itinerary.get({id : $stateParams.id}).$promise;
                }]
            }
        })
        .state('itinerary.new', {
            parent: 'itinerary',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'DateUtil', function($stateParams, $state, $uibModal, DateUtil) {
                $uibModal.open({
                    templateUrl: 'app/entities/itinerary/itinerary-dialog.html',
                    controller: 'ItineraryDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            var dt = DateUtil.utcNow();
                            return {
                                creationDate: null,
                                plannedDate: dt,
                                number: null,
                                startTime: dt,
                                endTime: dt,
                                startOdometer: null,
                                endOdometer: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('itinerary', null, { reload: true });
                }, function() {
                    $state.go('itinerary');
                });
            }]
        })
        .state('itinerary.edit', {
            parent: 'itinerary',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/itinerary/itinerary-dialog.html',
                    controller: 'ItineraryDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Itinerary', function(Itinerary) {
                            return Itinerary.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('itinerary', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('itinerary.delete', {
            parent: 'itinerary',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/itinerary/itinerary-delete-dialog.html',
                    controller: 'ItineraryDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Itinerary', function(Itinerary) {
                            return Itinerary.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('itinerary', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('itinerary-messages', {
            parent: 'itinerary',
            url: '/{id}/messages',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/message/messages-dialog.html',
                    controller: 'MessagesDialogController',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                        entity: ['Message', function(Message) {
                            return Message.getAllByItinerary({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('message');
                    return $translate.refresh();
                }]
            }
        })
        .state('tasks', {
            parent: 'itinerary',
            url: '/{id}/tasks',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/task/itinerary-task-dialog.html',
                    controller: 'ItineraryTaskDialogController',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                        entity: ['Task', function(Task) {
                            return Task.getAllByItinerary({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('task');
                    $translatePartialLoader.addPart('taskType');
                    $translatePartialLoader.addPart('taskStatus');
                    $translatePartialLoader.addPart('address');
                    $translatePartialLoader.addPart('visit');
                    $translatePartialLoader.addPart('invoice');
                    return $translate.refresh();
                }]
            }
        });
    }

})();
