(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('VehiclePaymentEditController', VehiclePaymentEditController);

    VehiclePaymentEditController.$inject = ['$timeout', '$scope', '$stateParams', 'entity', 'VehiclePayment', 'VehiclePaymentItem', '$state',
    'Vehicle', '$uibModal', 'DateUtils'];

    function VehiclePaymentEditController ($timeout, $scope, $stateParams, entity, VehiclePayment, VehiclePaymentItem, $state, Vehicle, $uibModal, DateUtils) {
        var vm = this;

        vm.vehiclePayment = entity;
        vm.vehicleTimeAllowed = minutesToHoursFormatter(vm.vehiclePayment.timeAllowed);
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.convertMinutesToDisplay = DateUtils.convertMinutesToDisplay;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function save () {
            vm.isSaving = true;
            if (vm.vehiclePayment.id !== null) {
                VehiclePayment.update(vm.vehiclePayment, onSaveSuccess, onSaveError);
            } else {
                VehiclePayment.save(vm.vehiclePayment, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('fleetApp:vehiclePaymentUpdate', result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.creation = false;
        vm.datePickerOpenStatus.startDate = false;
        vm.datePickerOpenStatus.endDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.cancel = function() {
            $state.go('^');
        }

        vm.deleteItem = function(vehiclePaymentItem) {
            $uibModal.open({
                templateUrl: 'app/entities/vehicle-payment-item/vehicle-payment-item-delete-dialog.html',
                controller: 'VehiclePaymentItemDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    vehiclePaymentItem: vehiclePaymentItem,
                    vehiclePayment: vm.vehiclePayment
                }
            }).result.then(function() {
                $state.go($state.$current, null, { reload: true });
            }, function() {
                $state.go('^');
            });
        }

        vm.editItem = function(itemId) {
            $uibModal.open({
                    templateUrl: 'app/entities/vehicle-payment-item/vehicle-payment-item-dialog.html',
                    controller: 'VehiclePaymentItemDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        vehiclePayment: vm.vehiclePayment,
                        entity: function () {
                            return VehiclePaymentItem.get({id : itemId}).$promise;
                        }
                    }
                }).result.then(function() {
                    $state.go($state.$current, null, { reload: true });
                }, function() {
                    $state.go($state.$current, null, { reload: true });
                });
        };


         function getNextDateFromItens() {
            if (vm.vehiclePayment.itens.length == 0) {
                return moment(vm.vehiclePayment.startDate).startOf('day').toDate();
            } else {
                var dates = [];

                angular.forEach(vm.vehiclePayment.itens, function(value) {
                     dates.push(moment(value.paymentDate, 'YYYY-MM-DD').startOf('day').toDate());
                 });
                 // get max date:
                 var dt = new Date(Math.max.apply(null, dates));
                 return moment(dt).add(1, 'day').startOf('day').toDate();
            }

         }

         vm.importItineraryData = function() {
            VehiclePayment.importItineraryData({id: vm.vehiclePayment.id}, function() {
                $state.go($state.$current, null, { reload: true });
            }, onSaveError);
         }

        vm.addItem = function() {
            $uibModal.open({
                    templateUrl: 'app/entities/vehicle-payment-item/vehicle-payment-item-dialog.html',
                    controller: 'VehiclePaymentItemDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        vehiclePayment: vm.vehiclePayment,
                        entity: function () {
                            var refDate = moment().startOf('day').add(8,'hour');
                            var endRefDate = moment(refDate.toDate()).add(10, 'hour');
                            var nextDate = getNextDateFromItens();

                            return {
                                paymentDate: nextDate,
                                startOdometer: 0,
                                endOdometer: 0,
                                kmTotal: 0,
                                kmExtra: 0,
                                kmExtraRegular: 0,
                                kmExtraTrip: 0,
                                kmCostTotalRegular: 0,
                                kmCostTotalTrip: 0,
                                startTime: refDate.toDate(),
                                endTime: endRefDate.toDate(),
                                totalTime: moment.duration(endRefDate.diff(refDate)).asMinutes(),
                                extraTime: 0,
                                extraTimeCostTotal: null,
                                expenseTotal: 0,
                                refundTotal: 0,
                                total: vm.vehiclePayment.dailyCost,
                                dailyCost: vm.vehiclePayment.dailyCost,
                                id: null,
                                type: null,
                                driverId: vm.vehiclePayment.preferentialDriverId,
                                driverFirstName: vm.vehiclePayment.preferentialDriverFirstName,
                                expenses: [],
                                refunds: []
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go($state.$current, null, { reload: true });
                }, function() {
                    $state.go($state.$current, null, { reload: true });
                });
        }

        function minutesToHoursFormatter(value) {
            if (value) {
                return moment().startOf('day').add(value, 'minutes').format('HH:mm');
            } else {
                return undefined;
            }
        }

    }
})();
