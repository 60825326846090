(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('VehicleGroupDetailController', VehicleGroupDetailController);

    VehicleGroupDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'VehicleGroup', 'Company'];

    function VehicleGroupDetailController($scope, $rootScope, $stateParams, entity, VehicleGroup, Company) {
        var vm = this;

        vm.vehicleGroup = entity;

        var unsubscribe = $rootScope.$on('fleetApp:vehicleGroupUpdate', function(event, result) {
            vm.vehicleGroup = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
