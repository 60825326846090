(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('ItineraryTaskDialogController', ItineraryTaskDialogController);

    ItineraryTaskDialogController.$inject = ['AlertService', '$scope', '$uibModalInstance', 'entity', 'Visit', 'InvoiceItem'];

    function ItineraryTaskDialogController (AlertService, $scope, $uibModalInstance, entity, Visit, InvoiceItem) {
        var vm = this;

        vm.tasks = entity;
        vm.clear = clear;
        vm.openDialog = openDialog;

        vm.isCollapsed = true;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function openDialog(task) {
            if (task.isCollapsed == undefined) {
                task.isCollapsed = true;
            }

            InvoiceItem.getInvoiceItens({taskid: task.id}, onSuccess, onError);
            function onSuccess(data, headers) {
                task.itens = data;
            }

            task.isCollapsed = !task.isCollapsed;
        }

        vm.showVisits = function(task) {
            if (!(task.visits == undefined)) {
                return;
            }
            Visit.getByTask({id: task.id}, onSuccess, onError);
            function onSuccess(data, headers) {
                task.visits = data;
            }
        }

        vm.arrayToString = function(string){
            return string.join(", ");
        };

    }
})();

