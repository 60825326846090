(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('VehiclePaymentItemDetailController', VehiclePaymentItemDetailController);

    VehiclePaymentItemDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'VehiclePaymentItem', 'VehiclePayment', 'User'];

    function VehiclePaymentItemDetailController($scope, $rootScope, $stateParams, entity, VehiclePaymentItem, VehiclePayment, User) {
        var vm = this;

        vm.vehiclePaymentItem = entity;

        var unsubscribe = $rootScope.$on('fleetApp:vehiclePaymentItemUpdate', function(event, result) {
            vm.vehiclePaymentItem = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
