(function() {
    'use strict';
    angular
        .module('fleetApp')
        .factory('VehiclePayment', VehiclePayment);

    VehiclePayment.$inject = ['$resource', 'DateUtils'];

    function VehiclePayment ($resource, DateUtils) {
        var resourceUrl =  'api/vehicle-payments/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.creation = DateUtils.convertDateTimeFromServer(data.creation);
                        data.startDate = DateUtils.convertLocalDateFromServer(data.startDate);
                        data.endDate = DateUtils.convertLocalDateFromServer(data.endDate);
                        if (data.itens) {
                            angular.forEach(data.itens, function (item) {
                                if (item.expenses) {
                                    angular.forEach(item.expenses, function(expense) {
                                        expense = angular.fromJson(expense);
                                        expense.creation = DateUtils.convertDateTimeFromServer(expense.creation);
                                        expense.expenseDate = DateUtils.convertDateTimeFromServer(expense.expenseDate);
                                        expense.newDate = new Date();
                                    });
                                }
                            });
                        }
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    data.startDate = DateUtils.convertLocalDateToServer(data.startDate);
                    data.endDate = DateUtils.convertLocalDateToServer(data.endDate);
                    return angular.toJson(data);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    data.startDate = DateUtils.convertLocalDateToServer(data.startDate);
                    data.endDate = DateUtils.convertLocalDateToServer(data.endDate);
                    return angular.toJson(data);
                }
            },
            'deleteItem': {
                method: 'DELETE', url: 'api/vehicle-payments/delete-item/:itemId/:vehiclePaymentId'
            },
            'importItineraryData': {
                method: 'GET',
                url: 'api/vehicle-payments/import-itinerary-data/:id'
            }
        });
    }
})();
