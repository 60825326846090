(function() {
    'use strict';

    angular
        .module('fleetApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('expense', {
            parent: 'entity',
            url: '/expense?page&sort&search&from&to&plate&vehicleId&vehiclePlate',
            data: {
                authorities: ['ROLE_USER', 'EDIT_FINANCIAL'],
                pageTitle: 'fleetApp.expense.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/expense/expenses.html',
                    controller: 'ExpenseController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null,
                from: null,
                to: null,
                vehiclePlate: null,
                vehicleId: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', '$filter', function ($stateParams, PaginationUtil, $filter) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                        from: $stateParams.from == null ? $filter('date')(new Date(), "yyyy-MM-dd") : $stateParams.from,
                        to: $stateParams.to == null ? $filter('date')(new Date(), "yyyy-MM-dd") : $stateParams.to,
                        vehicleId: $stateParams.vehicleId,
                        vehiclePlate: $stateParams.vehiclePlate
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('expense');
                    $translatePartialLoader.addPart('expenseType');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('expense-detail', {
            parent: 'entity',
            url: '/expense/{id}',
            data: {
                authorities: ['ROLE_USER', 'EDIT_FINANCIAL'],
                pageTitle: 'fleetApp.expense.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/expense/expense-detail.html',
                    controller: 'ExpenseDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('expense');
                    $translatePartialLoader.addPart('expenseType');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Expense', function($stateParams, Expense) {
                    return Expense.get({id : $stateParams.id}).$promise;
                }]
            }
        })
        .state('expense.new', {
            parent: 'expense',
            url: '/new',
            data: {
                authorities: ['ROLE_USER', 'EDIT_FINANCIAL'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/expense/expense-dialog.html',
                    controller: 'ExpenseDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                creation: null,
                                expenseType: null,
                                total: null,
                                expenseDate: null,
                                filemedia: null,
                                filemediaContentType: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('expense', null, { reload: true });
                }, function() {
                    $state.go('expense');
                });
            }]
        })
        .state('expense.edit', {
            parent: 'expense',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER', 'EDIT_FINANCIAL'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/expense/expense-dialog.html',
                    controller: 'ExpenseDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Expense', function(Expense) {
                            return Expense.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('expense', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('expense.delete', {
            parent: 'expense',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER', 'EDIT_FINANCIAL'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/expense/expense-delete-dialog.html',
                    controller: 'ExpenseDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Expense', function(Expense) {
                            return Expense.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('expense', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
