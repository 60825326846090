(function() {
    'use strict';

    var productTypeahead = {
        template: '<div class="form-group">' +
            '<label class="control-label" translate="global.field.product" for="field_product">Product</label>' +
            '<div class="input-group">' +
            ' <input type="text" ng-model="$ctrl.product" placeholder="{{ \'global.field.product\' | translate }}" ' +
            '  uib-typeahead="address as address.name for address in getLocation($viewValue)" ' +
            '  typeahead-loading="loadingLocations" typeahead-no-results="noResults" class="form-control">' +
            ' <span class="input-group-btn"> ' +
            '   <button type="button" class="btn btn-default" ng-click="$ctrl.clear()"><i class="glyphicon glyphicon-remove"></i></button> ' +
            ' </span>'+
            '</div>' +
            '<i ng-show="loadingLocations" class="glyphicon glyphicon-refresh"></i>' +
            ' <div ng-show="noResults">' +
            '   <i class="glyphicon glyphicon-remove"></i> <span translate="error.notFound"></span>' +
            ' </div>' +
            '</div>',
        controller: ProductTypeaheadController,
        bindings: {
            product: '='
        }
    };

    angular
        .module('fleetApp')
        .component('productTypeahead', productTypeahead);

    ProductTypeaheadController.$inject = ['$scope', 'Product', '$http', 'paginationConstants'];

    function ProductTypeaheadController($scope, Product, $http, paginationConstants) {
        var vm = this;

        vm.$onInit = function () {
            if (vm.product && vm.product.id && vm.product.name == undefined) {
            }
        };

        $scope.getLocation = function(val) {
            return Product.findByName({
                sort: '',
                page: 0,
                size: paginationConstants.itemsPerPageTypeahead,
                name: val}).$promise;
        };

        vm.clear = function() {
            vm.product = undefined;
            $scope.noResults = false;
        }
    }
})();
