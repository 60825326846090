(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('PersonAddressDetailController', PersonAddressDetailController);

    PersonAddressDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PersonAddress', 'Person', 'Address'];

    function PersonAddressDetailController($scope, $rootScope, $stateParams, entity, PersonAddress, Person, Address) {
        var vm = this;

        vm.personAddress = entity;

        var unsubscribe = $rootScope.$on('fleetApp:personAddressUpdate', function(event, result) {
            vm.personAddress = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
