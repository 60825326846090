(function() {
    'use strict';
    angular
        .module('fleetApp')
        .factory('VehiclePaymentItem', VehiclePaymentItem);

    VehiclePaymentItem.$inject = ['$resource', 'DateUtils'];

    function VehiclePaymentItem ($resource, DateUtils) {
        var resourceUrl =  'api/vehicle-payment-items/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.paymentDate = DateUtils.convertLocalDateFromServer(data.paymentDate);
                        data.startTime = DateUtils.convertDateTimeFromServer(data.startTime);
                        data.endTime = DateUtils.convertDateTimeFromServer(data.endTime);

                        if (data.expenses) {
                            angular.forEach(data.expenses, function(expense) {
                                expense = angular.fromJson(expense);
                                expense.creation = DateUtils.convertDateTimeFromServer(expense.creation);
                                expense.expenseDate = DateUtils.convertDateTimeFromServer(expense.expenseDate);
                                expense.newDate = new Date();
                            });
                        }
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    data.paymentDate = DateUtils.convertLocalDateToServer(data.paymentDate);
                    return angular.toJson(data);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    data.paymentDate = DateUtils.convertLocalDateToServer(data.paymentDate);
                    return angular.toJson(data);
                }
            }
        });
    }
})();
