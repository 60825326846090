(function() {
    'use strict';

    angular
        .module('fleetApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('dashboard', {
            parent: 'app',
            url: '/dashboard?date&view',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'fleetApp.dashboard.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/dashboard/dashboards.html',
                    controller: 'DashboardController',
                    controllerAs: 'vm'
                }
            },
            params:  {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null,
                    date: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'DateUtils',  function ($stateParams, DateUtils) {
                    return {
                        date: $stateParams.date == null ? DateUtils.newDateString() : $stateParams.date,
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('itinerary');
                    $translatePartialLoader.addPart('dashboard');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('dashboard.new', {
            parent: 'dashboard',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'DateUtils', function($stateParams, $state, $uibModal, DateUtils) {
                $uibModal.open({
                    templateUrl: 'app/entities/itinerary/itinerary-dialog.html',
                    controller: 'ItineraryDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            var dt = DateUtils.utcNow();
                            return {
                                creationDate: null,
                                number: null,
                                plannedDate: dt,
                                startTime: dt,
                                endTime: dt,
                                startOdometer: null,
                                endOdometer: null,
                                id: null,
                                tasks: []
                            };
                        },
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('itinerary');
                            $translatePartialLoader.addPart('task');
                            $translatePartialLoader.addPart('taskStatus');
                            $translatePartialLoader.addPart('visit');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        // QUANDO USUÁRIO CLICA SOBRE A ENTREGA
        .state('dashboard.edit', {
            parent: 'dashboard',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/dashboard/dashboard-dialog.html',
                    controller: 'DashboardDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Dashboard', function(Dashboard) {
                            return Dashboard.getTask({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('dashboard', null, { reload: 'dashboard' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('dashboard.messages-modal',{
            parent: 'dashboard',
            url: '/{id}/messages-modal',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/message/messages-dialog.html',
                    controller: 'MessagesDialogController',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                        entity: ['Message', function(Message) {
                            return Message.getAllByItinerary({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('message');
                    return $translate.refresh();
                }]
            }
        })
        // QUANDO USUÁRIO CLICA SOBRE UMA ENTREGA
        .state('dashboard.task-detail', {
            parent: 'dashboard',
            url: '/{id}/task-detail',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/task/task-dialog-detail.html',
                    controller: 'TaskDialogDetailController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Task', function(Task) {
                            return Task.get({id : $stateParams.id}).$promise;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('task');
                            $translatePartialLoader.addPart('taskType');
                            $translatePartialLoader.addPart('taskStatus');
                            $translatePartialLoader.addPart('taskEvent');
                            $translatePartialLoader.addPart('taskEventType');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('dashboard.collect-detail', {
            parent: 'dashboard',
            url: '/{id}/collect-detail',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/collect/collect-dialog-detail.html',
                    controller: 'CollectDialogDetailController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Task', function(Task) {
                            return Task.get({id : $stateParams.id}).$promise;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('task');
                            $translatePartialLoader.addPart('taskType');
                            $translatePartialLoader.addPart('taskStatus');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        // QUANDO USUÁRIO CLICA SOBRE UM DETALHE DE PERSON NO MAPA
        .state('dashboard.person', {
            parent: 'dashboard',
            url: '/{id}/person',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/person/person-detail-dialog.html',
                    controller: 'PersonDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Person', function(Person) {
                            return Person.get({id : $stateParams.id}).$promise;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('person');
                            $translatePartialLoader.addPart('personType');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        // Office Hours
        .state('dashboard.officehours-modal',{
            parent: 'dashboard',
            url: '/{itineraryId}/officehours-modal',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/officehours/officehour-dashboard-dialog.html',
                    controller: 'OfficeHourDashboardController',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                        entity: ['Officehours', function(Officehours) {
                            return Officehours.getAllByItineraryDate({date: $stateParams.date, itineraryId: $stateParams.itineraryId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('dashboard', null, { reload: false });
                }, function() {
                    $state.go('dashboard');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('officehours');
                    return $translate.refresh();
                }]
            }
        })
        .state('dashboard.officehours-modal.edit',{
            parent: 'dashboard.officehours-modal',
            url: '/{id}/officehours-modal/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/officehours/officehours-dialog.html',
                    controller: 'OfficehoursDialogController',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                        entity: ['Officehours', function(Officehours) {
                            return Officehours.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    console.log('333')
                    $state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
    }

})();
