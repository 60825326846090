(function() {
    'use strict';

    var collectItem = {
        templateUrl: 'app/entities/collect/collect-item.component.html',
        controller: CollectController,
        bindings: {
            task: '='
        }
    };

    angular
        .module('fleetApp')
        .component('collectItem', collectItem);

    CollectController.$inject = ['$scope', 'Itinerary', '$http', 'AlertService', '$state', '$uibModal'];

    function CollectController($scope, Itinerary, $http, AlertService, $state, $uibModal) {
        var vm = this;

        function onError(error) {
            AlertService.error(error.data.message);
        }

    }
})();
