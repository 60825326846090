(function() {
    'use strict';

    var userItinerarySelector = {
        templateUrl: 'app/entities/user-itinerary/user-itinerary-selector.html',
        controller: UserItinerarySelectorController,
        bindings: {
            itinerary: '='
        }
    };

    angular
        .module('fleetApp')
        .component('userItinerarySelector', userItinerarySelector);

    UserItinerarySelectorController.$inject = ['AlertService'];

    function UserItinerarySelectorController(AlertService) {
        var vm = this;

        vm.removeUser = function(userId) {
            var pos;
            angular.forEach(vm.itinerary.users, function (element) {
                if (element.userId === userId) {
                    pos = vm.itinerary.users.indexOf(element);
                }
            });
            if (pos > -1) vm.itinerary.users.splice(pos, 1);
        };

        vm.addUser = function() {
            var item = { userId: vm.userId, userFirstName: vm.userFirstName };

            if (!vm.itinerary.users) vm.itinerary.users = [];
            
            var duplicated = false;
            angular.forEach(vm.itinerary.users, function (element) {
                if (element.userId === item.userId) duplicated = true;
            });
           
            if (!duplicated) {
                vm.itinerary.users.unshift(item);
                vm.userId = null;
                vm.userFirstName = null;
            } else {
                AlertService.error('Esse item já existe');
            }
        };
    }
})();
