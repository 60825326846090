(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('JobErrorDetailController', JobErrorDetailController);

    JobErrorDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'JobError', 'Message'];

    function JobErrorDetailController($scope, $rootScope, $stateParams, entity, JobError, Message) {
        var vm = this;

        vm.jobError = entity;

        var unsubscribe = $rootScope.$on('fleetApp:jobErrorUpdate', function(event, result) {
            vm.jobError = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
