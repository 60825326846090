(function() {
    "use strict";

    angular
        .module("fleetApp")
        .controller(
            "UserManagementDialogController",
            UserManagementDialogController
        );

    UserManagementDialogController.$inject = [
        "$timeout",
        "$scope",
        "$uibModalInstance",
        "entity",
        "User",
        "JhiLanguageService",
        "authorities"
    ];

    function UserManagementDialogController(
        $timeout,
        $scope,
        $uibModalInstance,
        entity,
        User,
        JhiLanguageService,
        authorities
    ) {
        var vm = this;

        vm.authorities = authorities;
        vm.clear = clear;
        vm.languages = null;
        vm.save = save;
        vm.user = entity;

        JhiLanguageService.getAll().then(function(languages) {
            vm.languages = languages;
        });

        $timeout(function() {
            angular.element(".form-group:eq(1)>input").focus();
            initialize();
        });

        function initialize() {
            // eslint-disable-next-line no-undef
            var myWidget = cloudinary.createUploadWidget(
                {
                    cloudName: "dvzlcxrvw",
                    multiple: false,
                    defaultSource: "local",
                    uploadPreset: "usuarios"
                },
                function(error, result) {
                    if (!error && result && result.event === "success") {
                        vm.user.photoUrl = result.info.url;
                        $scope.$apply();
                    }
                }
            );

            // eslint-disable-next-line angular/document-service
            document.getElementById("upload_widget").addEventListener(
                "click",
                function() {
                    myWidget.open();
                },
                false
            );
        }

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function save() {
            vm.isSaving = true;
            if (vm.user.id !== null) {
                User.update(vm.user, onSaveSuccess, onSaveError);
            } else {
                User.save(vm.user, onSaveSuccess, onSaveError);
            }
        }
    }
})();
