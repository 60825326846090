(function() {
    "use strict";

    var dashboardGraph = {
        templateUrl: "app/entities/dashboard/dashboard-graph-template.html",
        controller: DashboardGraphController,
        bindings: {
            date: "<"
        }
    };

    angular.module("fleetApp").component("dashboardGraph", dashboardGraph);

    DashboardGraphController.$inject = [
        "$scope",
        "Itinerary",
        "Position",
        "AlertService",
        "$state",
        "$uibModal"
    ];

    function DashboardGraphController(
        $scope,
        Itinerary,
        Position,
        AlertService,
        $state,
        $uibModal
    ) {
        var vm = this;

        vm.refresh = function() {
            Itinerary.graphlist(
                { date: vm.date },
                function(data) {
                    vm.groups = data;
                },
                onError
            );
        };

        this.$onChanges = function(changes) {
            vm.refresh();
        };

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function diffInDays(date1, date2) {
            var diffTime = Math.abs(date2 - date1);
            var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            return diffDays;
        }

        vm.taskHourStyle = function(d1, d2) {
            if (diffInDays(new Date(d1), new Date(d2)) > 1) {
                return 'text-danger';
            }
            return ''
        }

        vm.taskStyle = function(task) {
            if (task === "COMPLETED") {
                return { color: "#00a65a", "text-align": "center" };
            } else if (task === "PARTIALLY_DONE") {
                return { color: "#f39c12" };
            } else if (task === "REJECTED") {
                return { color: "#f56954" };
            }
            return "";
        };
        vm.taskClass = function(task) {
            if (task.taskType === "WAREHOUSE_DEPARTURE")
                return "fa fa-industry fa-4x";
            if (task.taskType === "WAREHOUSE_ARRIVAL")
                return "fa fa-industry fa-4x";

            if (task.taskStatus === "IN_PROGRESS") {
                return "fa fa-dropbox fa-4x";
            } else {
                if (task.taskType === "COLLECT") return "fa fa-exchange fa-4x";
                return "fa fa-hospital-o fa-4x";
            }
        };

        vm.onTaskClicked = function(task) {
            if (task.taskType === "DELIVER")
                $state.go("dashboard.task-detail", { id: task.id });
            if (task.taskType === "COLLECT")
                $state.go("dashboard.collect-detail", { id: task.id });
        };

        vm.onOfficeHourClicked = function(itinerary, dashboardDate) {
            $uibModal.open({
                templateUrl: "app/entities/officehours/officehour-dashboard-dialog.html",
                controller: "OfficeHourDashboardController",
                controllerAs: "vm",
                size: "lg",
                resolve: {
                    entities: ['Officehours', function(Officehours) {
                        return Officehours.getAllByItineraryDate({date: dashboardDate, itineraryId: itinerary.id}).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('officehours');
                        return $translate.refresh();
                    }]
                }
            });
        };

        vm.onItineraryMapClicked = function(itineraryId, vehicleId) {
            $uibModal.open({
                templateUrl: "app/entities/dashboard/itinerary-map-dialog.html",
                controller: "ItineraryMapDialogController",
                controllerAs: "vm",
                size: "lg",
                resolve: {
                    geojson: [
                        "Itinerary",
                        function(Itinerary) {
                            return Itinerary.geojson({ id: itineraryId })
                                .$promise;
                        }
                    ],
                    positions: [
                        "Position",
                        function(Position) {
                            return Position.devicetime({
                                itineraryId: itineraryId,
                                vehicleid: vehicleId
                            }).$promise;
                        }
                    ]
                }
            });
        };

        vm.onDeleteItineraryClicked = function(itineraryId) {
            $uibModal
                .open({
                    templateUrl:
                        "app/entities/itinerary/itinerary-delete-dialog.html",
                    controller: "ItineraryDeleteController",
                    controllerAs: "vm",
                    size: "md",
                    resolve: {
                        entity: [
                            "Itinerary",
                            function(Itinerary) {
                                return Itinerary.get({ id: itineraryId })
                                    .$promise;
                            }
                        ]
                    }
                })
                .result.then(
                    function() {
                        $state.go($state.$current, null, { reload: true });
                    },
                    function() {
                        //                $state.go('^');
                    }
                );
        };

        vm.arrayToString = function(string) {
            return string.join(", ");
        };

        vm.onEditItineraryClicked = function(itineraryId) {
            $uibModal
                .open({
                    templateUrl: "app/entities/itinerary/itinerary-dialog.html",
                    controller: "ItineraryDialogController",
                    controllerAs: "vm",
                    backdrop: "static",
                    size: "lg",
                    resolve: {
                        entity: [
                            "Itinerary",
                            function(Itinerary) {
                                return Itinerary.get({ id: itineraryId })
                                    .$promise;
                            }
                        ],
                        translatePartialLoader: [
                            "$translate",
                            "$translatePartialLoader",
                            function($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart("itinerary");
                                $translatePartialLoader.addPart("task");
                                $translatePartialLoader.addPart("taskStatus");
                                $translatePartialLoader.addPart("visit");
                                $translatePartialLoader.addPart("global");
                                return $translate.refresh();
                            }
                        ]
                    }
                })
                .result.then(
                    function() {
                        $state.go($state.$current, null, { reload: true });
                    },
                    function() {
                        //                $state.go('^');
                    }
                );
        };
    }
})();
