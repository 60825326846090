(function() {
  'use strict';

  angular.module('fleetApp').factory('GeoMapService', GeoMapService);

  GeoMapService.$inject = ['$http', '$rootScope'];

  function GeoMapService($http, $rootScope) {
    var service = {
      initialLocation: initialLocation
    };

    return service;

    function initialLocation() {
      $http.get('api/fleet-preferences/initial-map-position').then(function(value) {
        $rootScope.initialLocation = value;
      });
      return $http.get('api/fleet-preferences/initial-map-position');
    }
  }
})();
