(function() {
    'use strict';
    angular
        .module('fleetApp')
        .factory('Dashboard', Dashboard);

    Dashboard.$inject = ['$resource', 'DateUtils'];

    function Dashboard ($resource, DateUtils) {
        var resourceUrl =  'api/dashboards/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'build': {
                url: 'api/dashboards/build',
                method: 'POST',
                transformRequest: function (data) {
                    data.dashboardDate = DateUtils.convertLocalDateToServer(data.dashboardDate);
                    return angular.toJson(data);
                }
            },
            'getTask': {
                url: 'api/dashboards/task/:id',
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
