(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('ReportController', ReportController);

    ReportController.$inject = ['$scope', '$state', 'Report', 'ParseLinks', 'AlertService', 'pagingParams',
         'paginationConstants', 'DateUtils', '$http'];

    function ReportController ($scope, $state, Report, ParseLinks, AlertService, pagingParams,
        paginationConstants, DateUtils, $http) {

        var vm = this;

        vm.report = {};
        vm.report.startDate = DateUtils.convertLocalDateFromServer(pagingParams.inidate);
        vm.report.endDate = DateUtils.convertLocalDateFromServer(pagingParams.enddate);
        vm.secondExternalId = pagingParams.secondExternalId;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.product = pagingParams.product;
        vm.person = pagingParams.person;

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;

        loadAll();

        function loadAll () {
            var productId = vm.product ? vm.product.id : undefined;
            var personId = vm.person ? vm.person.id : undefined;
            Report.analytic({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,

                startDate: vm.report.startDate,
                endDate: vm.report.endDate,
                secondExternalId: vm.secondExternalId,
                productId: productId,
                personId: personId
                }, onSuccess, onError);
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;

                vm.reports = data;
                vm.page = pagingParams.page;
                vm.date = pagingParams.date;
            }
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                product: vm.product,
                person: vm.person,
                secondExternalId: vm.secondExternalId,
                inidate: DateUtils.convertLocalDateToServer(vm.report.startDate),
                enddate: DateUtils.convertLocalDateToServer(vm.report.endDate)
            });
        }

        vm.datePickerOpenStatus.date = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

         vm.analyticReport = function(id) {
             var dto = {
                 productId: vm.product,
                 personId: vm.person,
                 secondExternalId: vm.secondExternalId,
                 startDate: DateUtils.convertLocalDateToServer(vm.report.startDate),
                 endDate: DateUtils.convertLocalDateToServer(vm.report.endDate)
             }
             $http.post('api/report/analytics/xls', dto, { responseType: 'arraybuffer' }).then(function (response) {
                 var blob = new Blob([response.data], {type: "application/vnd.ms-excel"});
                 saveAs(blob, "analytic.xls");
             }, onError);
         }
    }
})();
