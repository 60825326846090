(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('RefusedReasonDetailController', RefusedReasonDetailController);

    RefusedReasonDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'RefusedReason', 'Company'];

    function RefusedReasonDetailController($scope, $rootScope, $stateParams, entity, RefusedReason, Company) {
        var vm = this;

        vm.refusedReason = entity;

        var unsubscribe = $rootScope.$on('fleetApp:refusedReasonUpdate', function(event, result) {
            vm.refusedReason = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
