(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('DashboardController', DashboardController);

    DashboardController.$inject = ['AlertService', '$filter', '$scope', '$state', '$interval', 'pagingParams',
    'DateUtils']

    function DashboardController (AlertService, $filter, $scope, $state, $interval, pagingParams, DateUtils) {
        var vm = this;

        var dateFormat = 'yyyy-MM-dd';
        vm.dashboardDate = pagingParams.date;
        vm.pickerDate = DateUtils.convertLocalDateFromServer(pagingParams.date);

        $scope.clock = Date.now();
        $interval(function () { $scope.clock = Date.now(); }, 1000);

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;

        vm.overlayOptions = [];

        vm.datePickerOpenStatus.dashboardDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        /*
        * Page reload when notify is true.
        */
        vm.transitionTo = function(notify) {
            var fromDate = DateUtils.convertLocalDateToServer(vm.pickerDate);
            $state.transitionTo($state.$current, { date: fromDate }, {notify: notify});
        }

        vm.onTaskClicked = function(marker) {
            $state.go('dashboard.task-detail', {id: marker.model.id});
        }

    }
})();
