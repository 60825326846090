(function() {
    'use strict';
    angular
        .module('fleetApp')
        .factory('Task', Task);

    Task.$inject = ['$resource', 'DateUtils'];

    function Task ($resource, DateUtils) {
        var resourceUrl =  'api/tasks/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        angular.forEach(data.itens, function(item) {
                            item.expirationDate = DateUtils.convertLocalDateFromServer(item.expirationDate);
                        });
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getAllByItinerary': {
                method: 'GET', isArray: true,
                url: 'api/tasks/itinerary/:id',
                transformResponse: function (data, headersGetter, status) {
                    if (data) {
                        data = angular.fromJson(data);
                        if (status == 200) {
                            var models = [];
                            angular.forEach(data, function(item) {
                                item.isCollapsed = true;
                                models.push(item);
                            });
                            return models;
                        }
                    }
                    return data;
                }
            },
            'geojson': { method:'GET', url:'api/tasks/geojson', isArray: true},
        });
    }
})();
