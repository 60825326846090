(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('OfficehoursDetailController', OfficehoursDetailController);

    OfficehoursDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Officehours', 'User', 'Vehicle'];

    function OfficehoursDetailController($scope, $rootScope, $stateParams, entity, Officehours, User, Vehicle) {
        var vm = this;

        vm.officehours = entity;

        var unsubscribe = $rootScope.$on('fleetApp:officehoursUpdate', function(event, result) {
            vm.officehours = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
