(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('GeomapController', GeomapController);

    GeomapController.$inject = ['$rootScope', '$scope', 'GeoMap'];

    function GeomapController ($rootScope, $scope, GeoMap) {
        var vm = this;

        this.$onInit = function () {
            console.log('$onInit');
            $rootScope.$broadcast("clearMap");
            $rootScope.$broadcast("initialLoadMap");
        }

        // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

        vm.showHistoryPanel = function (vehicle) {
            GeoMap.vehicleHistory = vehicle;
        }

    }
})();
