(function() {
    'use strict';
    angular
        .module('fleetApp')
        .factory('Visit', Visit);

    Visit.$inject = ['$resource', 'DateUtils'];

    function Visit ($resource, DateUtils) {
        var resourceUrl =  'api/visits/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.arrivalTime = DateUtils.convertDateTimeFromServer(data.arrivalTime);
                        data.departure = DateUtils.convertDateTimeFromServer(data.departure);
                        data.creationDate = DateUtils.convertDateTimeFromServer(data.creationDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getByTask': {
                method: 'GET', isArray: true,
                url: 'api/visits/task/:id',
                transformResponse: function (data, headersGetter, status) {
                    if (data) {
                        data = angular.fromJson(data);
                         if (status == 200) {
                            angular.forEach(data, function(item) {
                                data.arrivalTime = DateUtils.convertDateTimeFromServer(data.arrivalTime);
                                data.departure = DateUtils.convertDateTimeFromServer(data.departure);
                                data.creationDate = DateUtils.convertDateTimeFromServer(data.creationDate);
                            });
                        }
                    }
                    return data;
                }
            }
        });
    }
})();
