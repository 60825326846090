(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('InvoiceDetailController', InvoiceDetailController);

    InvoiceDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Invoice', 'PersonAddress', 'InvoiceItem', 'Task', 'RefusedReason'];

    function InvoiceDetailController($scope, $rootScope, $stateParams, entity, Invoice, PersonAddress, InvoiceItem, Task, RefusedReason) {
        var vm = this;

        vm.invoice = entity;

        var unsubscribe = $rootScope.$on('fleetApp:invoiceUpdate', function(event, result) {
            vm.invoice = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
