(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('TimeReportController', TimeReportController);

    TimeReportController.$inject = ['$scope', '$state', '$http', 'AlertService', 'DateUtils'];

    function TimeReportController ($scope, $state, $http, AlertService, DateUtils) {
        var vm = this;

        vm.fortnight = function (status) {
            var dt = moment().startOf('month');
            if (status === 'first') {
                vm.startDate = dt.toDate();
                vm.endDate = dt.clone().add(14, 'd').toDate();
            } else {
                vm.startDate = dt.clone().add(15, 'd').toDate();
                vm.endDate = dt.clone().endOf('month').toDate();
            }
        }

        vm.fortnight('first');

        function onError(error) {
            AlertService.error(error.data.message);
        }

        vm.analyticReport = function(id) {
            var dto = {startDate: vm.startDate, endDate: vm.endDate, vehicleId: vm.vehicleId };
            $http.post('api/report/timeReport/', dto, { responseType: 'arraybuffer' }).then(function (response) {
                var blob = new Blob([response.data], {type: "application/vnd.ms-excel"});
                saveAs(blob, "TimeReport.xls");
            }, onError);
        }

    }
})();
