(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('VisitDetailController', VisitDetailController);

    VisitDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Visit', 'Task', 'Position'];

    function VisitDetailController($scope, $rootScope, $stateParams, entity, Visit, Task, Position) {
        var vm = this;

        vm.visit = entity;

        var unsubscribe = $rootScope.$on('fleetApp:visitUpdate', function(event, result) {
            vm.visit = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
