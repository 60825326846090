(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('TaskEventDetailController', TaskEventDetailController);

    TaskEventDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TaskEvent', 'User', 'Task'];

    function TaskEventDetailController($scope, $rootScope, $stateParams, previousState, entity, TaskEvent, User, Task) {
        var vm = this;

        vm.taskEvent = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('fleetApp:taskEventUpdate', function(event, result) {
            vm.taskEvent = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
