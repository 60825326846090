(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('PersonController', PersonController);

    PersonController.$inject = ['$scope', '$state', 'Person', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants'];

    function PersonController ($scope, $state, Person, ParseLinks, AlertService, pagingParams, paginationConstants) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.personId = pagingParams.personId;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.search = search;
        vm.isLoading = false;

        loadAll();

        function loadAll () {
            vm.isLoading = true;
            Person.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                personName: vm.personName
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.people = data;
                vm.page = pagingParams.page;
                vm.isLoading = false;
            }
        }

        function onError(error) {
            vm.isLoading = false;
            AlertService.error(error.data.message);
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                personId: vm.personId
            });
        }

        function search () {
            loadAll();
        }
    }
})();
