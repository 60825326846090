(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('VehicleGroupController', VehicleGroupController);

    VehicleGroupController.$inject = ['$scope', '$state', 'VehicleGroup'];

    function VehicleGroupController ($scope, $state, VehicleGroup) {
        var vm = this;
        
        vm.vehicleGroups = [];

        loadAll();

        function loadAll() {
            VehicleGroup.query(function(result) {
                vm.vehicleGroups = result;
            });
        }
    }
})();
