(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('CollectDialogDetailController', CollectDialogDetailController);

    CollectDialogDetailController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity'];

    function CollectDialogDetailController ($timeout, $scope, $stateParams, $uibModalInstance, entity) {
        var vm = this;

        vm.clear = clear;
        vm.task = entity;
        vm.radioModel = 'product';

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.arrayToString = function(string){
            return string.join(", ");
        };
    }
})();
