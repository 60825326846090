(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('ExpenseDetailController', ExpenseDetailController);

    ExpenseDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'DataUtils', 'entity', 'Expense', 'User', 'Vehicle', 'Company', 'FleetMedia'];

    function ExpenseDetailController($scope, $rootScope, $stateParams, DataUtils, entity, Expense, User, Vehicle, Company, FleetMedia) {
        var vm = this;

        vm.expense = entity;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('fleetApp:expenseUpdate', function(event, result) {
            vm.expense = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
