(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('VehicleDialogController', VehicleDialogController);

    VehicleDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Vehicle', 'Company', 'Device', 'VehicleType'];

    function VehicleDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Vehicle, Company, Device, VehicleType) {
        var vm = this;

        vm.vehicle = entity;
        vm.clear = clear;
        vm.save = save;
        vm.companies = Company.query();
//        vm.devices = Device.query();
        vm.vehicletypes = VehicleType.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();

            initialize();
        });

        function initialize() {
            var myWidget = cloudinary.createUploadWidget({
                cloudName: 'dvzlcxrvw', 
                multiple: false,
                defaultSource: "local",
                uploadPreset: 'vehicle' }, 
                function (error, result) { 
                    if (!error && result && result.event === "success") { 
                        vm.vehicle.photoUrl = result.info.url;
                        $scope.$apply();
                    }
                }
              );

            document.getElementById("upload_widget").addEventListener("click", function(){
                myWidget.open();
                }, false);
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.vehicle.id !== null) {
                Vehicle.update(vm.vehicle, onSaveSuccess, onSaveError);
            } else {
                Vehicle.save(vm.vehicle, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('fleetApp:vehicleUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
