(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('VisitDialogController', VisitDialogController);

    VisitDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Visit', 'Task', 'Position'];

    function VisitDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Visit, Task, Position) {
        var vm = this;

        vm.visit = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.tasks = Task.query();
//        vm.positions = Position.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.visit.id !== null) {
                Visit.update(vm.visit, onSaveSuccess, onSaveError);
            } else {
                Visit.save(vm.visit, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('fleetApp:visitUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.arrivalTime = false;
        vm.datePickerOpenStatus.departure = false;
        vm.datePickerOpenStatus.creationDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
