(function() {
    'use strict';

    var invoiceItem = {
        templateUrl: 'app/entities/invoice-item/invoice-item.component.html',
        controller: InvoiceItem,
        bindings: {
            itens: '<'
        }
    };

    angular
        .module('fleetApp')
        .component('invoiceItem', invoiceItem);

    InvoiceItem.$inject = ['$scope', 'Itinerary', '$http', 'AlertService', '$state', '$uibModal'];

    function InvoiceItem($scope, Itinerary, $http, AlertService, $state, $uibModal) {
        var vm = this;

        function onError(error) {
            AlertService.error(error.data.message);
        }

        vm.getTotal = function() {
            vm.total = [];
            angular.forEach(vm.itens, function(item) {
                vm.total.push(item.total);
            });
        }
        vm.getTotal();

        vm.getLineClass = function(invoiceItem) {
            if (invoiceItem.refusedReasonId > 0)
                return 'text-red';
            else
                return '';
        }

    }
})();
