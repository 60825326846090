(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$rootScope', '$scope', 'Principal', 'LoginService', '$state', '$timeout', 'pagingParams', 'Position', 'DateUtils',
    'Itinerary', '$filter', '$interval', 'AlertService', 'GeoMap', 'initialLocation'];

    function HomeController ($rootScope, $scope, Principal, LoginService, $state, $timeout, pagingParams, Position, DateUtils, Itinerary, $filter,
        $interval, AlertService, GeoMap, initialLocation) {
        var vm = this;

        $rootScope.initialLocation = initialLocation.data;

        vm.tab = pagingParams.tab;
        vm.pickerDate = DateUtils.convertStringToDateMomentMMDDYYYY(pagingParams.date);
        vm.vehicleId = pagingParams.vehicleId;
        vm.itineraryId = pagingParams.itineraryId;

        vm.datePickerOpenStatus = {};

        // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~


        vm.setTab = function(newTab){
          vm.tab = newTab;

          $rootScope.$broadcast("clearMap");

          if (newTab == 1) {
            vm.itineraryId = null;
            vm.vehicleId = null;
            $rootScope.$broadcast("showOnMapEvent", {data: vm.vehicles, fitBounds: false});
          }

          transition(false);
        };

        vm.isSet = function(tabNum){
          return vm.tab === tabNum;
        };

        vm.isItineratySet = function(itinerary) {
          return itinerary.id == vm.itineraryId;
        };

        vm.isVehicleSet = function(vehicle) {
            return vehicle.vehicleId == vm.vehicleId;
        };

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.onDateChange = function () {
            transition(true);
        }

        var initializing = true;
        $scope.$watch('vm.pickerDate', function () {
            if (initializing) {
                $timeout(function() { initializing = false; });
            } else {
                transition(true);
            }
        });

        // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        vm.selectItinerarie = function(itinerary) {
            vm.itineraryId = itinerary.id;
            vm.vehicleId = itinerary.vehicleId;
            transition(false);

            $rootScope.$broadcast('clearMap');

            Itinerary.geojson({id: itinerary.id}, function(data) {
                $rootScope.$broadcast("onItineraryClickEvent", {data: data, fitBounds: true});
            }, onError);

            Position.devicetime({itineraryId: itinerary.id, vehicleid: itinerary.vehicleId}, function(data){
                $rootScope.$broadcast("showPositions", {positions: data, fitBounds: true});
            }, onError);
        };

        vm.selectVehicle = function(vehicle) {

            $rootScope.$broadcast("clearMap");
            GeoMap.vehicleHistory = null; // disable history panel
            $rootScope.$broadcast("invalidateMapSize");

            vm.itineraryId = null;
            vm.vehicleId = vehicle.vehicleId;
            $rootScope.$broadcast("onVehicleClickEvent",vehicle);
            transition(false);
        };

        // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

        function transition (notify) {
            var dt = $filter('date')(vm.pickerDate, 'yyyy-MM-dd');

            $state.transitionTo($state.$current, {
                tab: vm.tab,
                date: dt,
                vehicleId: vm.vehicleId,
                itineraryId: vm.itineraryId
            }, {reload: false, notify: notify});
        }

        // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

        vm.showMsg = false;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;

        $scope.$on('authenticationSuccess', function() {
            vm.showMsg = true;
            getAccount();
            $timeout(function () { vm.showMsg = false; }, 3000);
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                if (!Principal.isAuthenticated()) {
                    $timeout(function() {
                        $state.go('login');
                    }, 0);
                }
            });
        }

        function register () {
            $state.go('register');
        }

        // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

        function onError(error) {
            AlertService.error(error.data.message);
        }

        // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

        var intervalPromise;
//        function load() {
//            if (vm.itineraryId != null) {
//
//                loadItinerary(vm.itineraryId, true, vm.vehicleId);
//
                intervalPromise = $interval(function() {
//                    loadItinerary(vm.itineraryId, false, vm.vehicleId);
                }, 10000);
//            }
//        }
//        load();

        $scope.$on('$destroy',function(){
            if(intervalPromise)
                $interval.cancel(intervalPromise);
        });

        function loadItinerary(itineraryId, fitBounds, vehicleId) {

            Itinerary.geojson({id: itineraryId}, function(data) {
                $rootScope.$broadcast("onItineraryClickEvent", {data: data, fitBounds: fitBounds});
            }, onError);

            Position.devicetime({itineraryId: itineraryId, vehicleid: vehicleId}, function(data){
                $rootScope.$broadcast("showPositions", {pcositions: data, fitBounds: fitBounds});
            }, onError);
        }

        // -------------------------------------------------
        // Initial loading

        function loadItineraries() {
            var fromDate = DateUtils.convertLocalDateToServer(vm.pickerDate);

            Itinerary.dashboardlist({date: fromDate}, function (data) {
                vm.itineraries = data;

                //AdminLte Tree component:
                var options = {animationSpeed: 501, accordion: true, followLink: false, trigger: ".treeview a", widget: "tree"};
                setTimeout(function(){ $('#itineraries-menu').tree(options); }, 100);
            }, onError);
        }

        vm.loadVehiclesPosition = function () {
            vm.loadVehicles(true);
        }

        vm.loadVehicles = function (fitBounds) {

            Position.lastposition({}, function(data){
                vm.vehicles = data;
                $rootScope.$broadcast("clearMap");
                $rootScope.$broadcast("showOnMapEvent", {data: data, fitBounds: fitBounds});

                //AdminLte Tree component:
                var options = {animationSpeed: 501, accordion: true, followLink: false, trigger: ".treeview a", widget: "tree"};
                setTimeout(function(){ $('#vehicles-menu').tree(options); }, 100);

                setTimeout(function(){ $('#vehicles-menu').tree(options); }, 100);
            });
        }

        $scope.$on('initialLoadMap', function () {
            vm.loadVehicles(false);
            loadItineraries();
        });

        $scope.$watch(
          function() { return GeoMap.vehicleHistory; },
          function(newVal, oldVal) {
            vm.showHistory = newVal;
          },
          true
        );


    }
})();
