(function() {
    'use strict';

    angular
        .module('fleetApp')
        .constant('paginationConstants', {
            'itemsPerPage': 20,
            'itemsPerPageTypeahead': 6
        });
})();
