(function() {
    'use strict';

    angular
        .module('fleetApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('home', {
            parent: 'app',
            url: '/?date?itineraryId?vehicleId?tab',
            data: {
                authorities: []
            },
            onEnter: ['$state', 'Principal', '$timeout', function($state, Principal, $timeout) {
                var isAuthenticated = Principal.isAuthenticated();
                if (!isAuthenticated) {
                    $timeout(function() {
                        $state.go('login');
                    }, 0);
                }
            }],
            views: {
                'content@': {
                    templateUrl: 'app/home/home.html',
                    controller: 'HomeController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                initialLocation: ['GeoMapService', 'Principal', function(GeoMapService, Principal) {
                    if (Principal.isAuthenticated()){
                        return GeoMapService.initialLocation();
                    } else {
                        return '';
                    }
                }],
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    $translatePartialLoader.addPart('vehicle');
                    return $translate.refresh();
                }],
                pagingParams: ['$stateParams', 'DateUtils', '$filter',  function ($stateParams, DateUtils, $filter) {
                    return {
                        date: $stateParams.date == null ? $filter('date')(new Date(), "yyyy-MM-dd") : $stateParams.date,
                        tab: $stateParams.tab == null ? 0 : parseInt($stateParams.tab),
                        itineraryId: $stateParams.itineraryId,
                        vehicleId: $stateParams.vehicleId
                    };
                }]
            },
        });
    }
})();
