(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('VehiclePaymentDeleteController',VehiclePaymentDeleteController);

    VehiclePaymentDeleteController.$inject = ['$uibModalInstance', 'entity', 'VehiclePayment'];

    function VehiclePaymentDeleteController($uibModalInstance, entity, VehiclePayment) {
        var vm = this;

        vm.vehiclePayment = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            VehiclePayment.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
