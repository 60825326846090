(function() {
    'use strict';
    angular
        .module('fleetApp')
        .factory('GeoMap', GeoMap);

    GeoMap.$inject = ['$resource'];

    function GeoMap ($resource) {

        return {
            vehicleHistory: {}
        };
    }
})();
