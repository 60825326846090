/* globals $ */
(function() {
    'use strict';

    angular
        .module('fleetApp')
        .directive('fullscreen', function() {
             return function (scope, element, attrs) {
                    var window_height = $(window).height(),
                    header_height = $(".navbar-header").height(),
                    footer_height = $('.main-footer').height();
                    footer_height = footer_height == null ? 0 : footer_height;
                    element.css("height", window_height - header_height - footer_height - 30);
             }
         });
})();
