(function() {
    'use strict';
    angular
        .module('fleetApp')
        .factory('Position', Position);

    Position.$inject = ['$resource', 'DateUtils'];

    function Position ($resource, DateUtils) {
        var resourceUrl =  'api/positions/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.serverTime = DateUtils.convertDateTimeFromServer(data.serverTime);
                        data.deviceTime = DateUtils.convertDateTimeFromServer(data.deviceTime);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'lastposition': {method:'GET', isArray: true, url: 'api/positions/lastposition'},
            'devicetime': {method:'GET', isArray: true, url: 'api/positions/devicetime'},
            'history': {method:'GET', isArray: true, url: 'api/positions/history'}
        });
    }
})();
