(function () {
    'use strict';

    angular
        .module('fleetApp')
        .constant('uiDatetimePickerConfig', {
            dateFormat: 'dd-MM-yyyy HH:mm', // yyyy-MM-dd HH:mm
            defaultTime: '00:00:00',
            html5Types: {
                date: 'dd-MM-yyyydd', // yyyy-MM-dd'
                'datetime-local': 'dd-MM-yyyyTHH:mm:ss.sss', //'yyyy-MM-ddTHH:mm:ss.sss'
                'month': 'MM-yyyy' // yyyy-MM
            },
            initialPicker: 'date',
            reOpenDefault: false,
            enableDate: true,
            enableTime: true,
            buttonBar: {
                show: true,
                now: {
                    show: true,
                    text: 'Agora',
                    cls: 'btn-sm btn-default'
                },
                today: {
                    show: true,
                    text: 'Hoje',
                    cls: 'btn-sm btn-default'
                },
                clear: {
                    show: true,
                    text: 'Limpar',
                    cls: 'btn-sm btn-default'
                },
                date: {
                    show: true,
                    text: 'Data',
                    cls: 'btn-sm btn-default'
                },
                time: {
                    show: true,
                    text: 'Hora',
                    cls: 'btn-sm btn-default'
                },
                close: {
                    show: true,
                    text: 'Fechar',
                    cls: 'btn-sm btn-default'
                }
            },
            closeOnDateSelection: true,
            closeOnTimeNow: true,
            appendToBody: false,
            altInputFormats: [],
            ngModelOptions: { timezone: null },
            saveAs: false,
            readAs: false
        });

//            uiDatetimePickerConfig.buttonBar.now.text = $translate('global.field.vehicle');
//            $translate('global.field.vehicle').then(function (title) {
//                uiDatetimePickerConfig.buttonBar.now.text = title;
//                console.log('global.field.vehicle' + title);
//            });
})();
