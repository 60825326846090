(function() {
    'use strict';

    angular
        .module('fleetApp')
        .factory('translationStorageProvider', translationStorageProvider);

    translationStorageProvider.$inject = ['$cookies', '$log', 'LANGUAGES', '$window'];

    function translationStorageProvider($cookies, $log, LANGUAGES, $window) {
        return {
            get: get,
            put: put
        };

        function get(name) {
            if (LANGUAGES.indexOf($cookies.getObject(name)) === -1) {
                var defaultLocation = getDefaultLanguage();
                $log.info('Resetting invalid cookie language "' + $cookies.getObject(name) + '" to prefered language ' + defaultLocation);
                $cookies.putObject(name, defaultLocation);
            }
            return $cookies.getObject(name);
        }

        function put(name, value) {
            $cookies.putObject(name, value);
        }


        function getDefaultLanguage() {
            var lang = $window.navigator.language || $window.navigator.userLanguage;
            $log.info('$window.navigator.language ' + lang);
            if (lang.toUpperCase().indexOf('PT') !== -1) {
                return 'pt-br';
            } else if (lang.toUpperCase().indexOf('EN') !== -1) {
                return 'en';
            } else {
                return 'en'; // default
            }
        }
    }
})();
