(function() {
  'use strict';

  angular
    .module('fleetApp', [
      'ngStorage',
      'tmh.dynamicLocale',
      'pascalprecht.translate',
      'ngResource',
      'ngCookies',
      'ngAria',
      'ngCacheBuster',
      'ngFileUpload',
      'ui.bootstrap',
      'ui.bootstrap.datetimepicker',
      'ui.router',
      'infinite-scroll',
      'ds.objectDiff',
      // jhipster-needle-angularjs-add-module JHipster will add new module here
      'angular-loading-bar',
      'angular.filter',
      'toaster',
      'ui.utils.masks'
    ])
    .run(run);

  run.$inject = ['stateHandler', 'translationHandler'];

  function run(stateHandler, translationHandler) {
    stateHandler.initialize();
    translationHandler.initialize();
  }
})();
