(function() {
    'use strict';

    var geomap = {
        templateUrl: 'app/entities/geomap/geomap.template.html',
        controller: GeomapController,
        bindings: {
            autoHeight: '@' // title hide when true
        }
    };

    angular
        .module('fleetApp')
        // This component can be used like: <geomap></geomap>
        .component('geomap', geomap);

    GeomapController.$inject = ['$rootScope', '$scope', '$state', '$timeout', '$uibModal', 'ImageService'];

    function GeomapController($rootScope, $scope, $state, $timeout, $uibModal, ImageService) {

        var vm = this;

        var mbAttr = 'Map data &copy; <a href=\"http://openstreetmap.org\">OpenStreetMap</a> contributors, <a href=\"http://creativecommons.org/licenses/by-sa/2.0/\">CC-BY-SA</a>, Imagery © <a href=\"http://mapbox.com\">Mapbox</a>';
        //var mbUrl = 'https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}';
        var mbUrl = 'https://api.mapbox.com/styles/v1/iuricmp/cjgse8rdj001l2rlbs29gnqw8/tiles/256/{z}/{x}/{y}?access_token={accessToken}'


        var layer1 = L.tileLayer(mbUrl, {
            attribution: mbAttr,
            maxZoom: 17,
            id: 'mapbox.streets',
            detectRetina: true,
            accessToken: 'pk.eyJ1IjoiaXVyaWNtcCIsImEiOiJjajc1OGhjYTEwZXRoMnhucmcxeWhvb3FoIn0.XIRpOfUltUn7YGoiboXTdw'
        });

        var mapJson = $rootScope.initialLocation ? $rootScope.initialLocation : { center: [-22.5410, -43.1227], zoom: 9 };
        mapJson['layers'] = [layer1];
        var myMap = L.map('mapid', mapJson);

        // var myMap = L.map('mapid', {center: [-22.5410, -43.1227], zoom: 9, layers: [layer1]});


        var baseLayers = {
            "Streets": layer1
        };
        L.control.layers(baseLayers).addTo(myMap);


        function invalidateSize () {
            if (vm.autoHeight) {
                setTimeout(function(){
                        $("#mapid").height($(window).height() - $('.main-header').height() - $('#vehicle-history').height() );
                        $(".content-map").css('padding-left', $('.main-sidebar').width());
                        myMap.invalidateSize();
                    }, 200);
            }
            myMap.invalidateSize();
        }

        invalidateSize();

        $rootScope.$on('invalidateMapSize', function () {
            invalidateSize();
        });

        var vehicleIcon = L.BeautifyIcon.icon({icon: 'truck', borderColor: '#0066a2', backgroundColor: '#0066a2', textColor: 'white', iconShape:
        'marker'});
        var redMarker = L.BeautifyIcon.icon({icon: 'power-off', borderColor: 'red', textColor: 'red', backgroundColor: 'transparent'});
        var speedMarker = L.BeautifyIcon.icon({icon: 'rocket', borderColor: 'yellow', textColor: 'yellow', backgroundColor: 'transparent'});

        var engineAndSpeedLayerGroup;

        var polyline = L.markerClusterGroup();
        var vehicleMarkers = L.markerClusterGroup({
                                        maxClusterRadius: 120,
                                        iconCreateFunction: function (cluster) {
                                            var markers = cluster.getAllChildMarkers();
                                            var n = markers.length;
                                            return L.divIcon({ html: n, className: 'vehicle-cluster',iconSize : L.icon({}) });
                                        },
                                        //Disable all of the defaults:
                                        spiderfyOnMaxZoom: true, showCoverageOnHover: false, zoomToBoundsOnClick: true
                                    });

        var tasksMarkers = L.markerClusterGroup({
                                         iconCreateFunction: function (cluster) {
                                             var markers = cluster.getAllChildMarkers();
                                             var n = markers.length;
                                             return L.divIcon({ html: n, className: 'delivery-cluster',iconSize : L.icon({}) });
                                         },
                                         //Disable all of the defaults:
                                         spiderfyOnMaxZoom: true, showCoverageOnHover: false, zoomToBoundsOnClick: true
                                     });

		tasksMarkers.on('clusterclick', function (a) {
			a.layer.zoomToBounds();
		});

        function clearMap() {
            vehicleMarkers.clearLayers();
            tasksMarkers.clearLayers();
            if (engineAndSpeedLayerGroup) engineAndSpeedLayerGroup.remove();
        }

        vm.selectVehicle = function(vehicle) {
            vm.map.center = {latitude: vehicle.latitude, longitude: vehicle.longitude};
            vm.map.zoom = 13;
        };

        $scope.$on("clearMap", function(evt){
            clearMap();
        });

        $scope.$on("showOnMapEvent", function(evt, data){

            invalidateSize();

            angular.forEach(data.data, function(v) {
                addVehicleToLayer(v);
            });

            myMap.addLayer(vehicleMarkers);

            if (data.fitBounds) {
                myMap.fitBounds(vehicleMarkers.getBounds());
            }
        });

        function vehicleTooltipTemplate(v) {
            var dt = v.deviceTime ? moment(v.deviceTime) : null;
            var dtStr = dt ? dt.utc().format('DD/MM/YYYY HH:mm') : '-';
            var avatarUrl = ImageService.thumbnail(v.vehiclePhotoUrl);
            var avatar = avatarUrl ? ("<img class='img-circle' src='" + avatarUrl +"' alt='Vehicle Avatar'>") : "";
            return (
                "<div class='widget-user-2'> " +
                " <div class='widget-user-header'> " +
                "  <div class='widget-user-image'> " +
                     avatar +
                "  </div>  " +
                "  <h3 class='widget-user-username'>" + v.vehiclePlate + "</h3>  " +
                "  <h5 class='widget-user-desc' ng-if='v.vehicleTypeName'>" + v.vehicleTypeName + "</h5>  " +
                " </div> " +
                " <div class='box-footer no-padding'> " +
                "  <div class='box-footer no-padding'>" +
                "      <ul class='nav nav-stacked'>" +
                "          <li><a>Latitude <span class='pull-right'>" + v.latitude + "</span></a></li>" +
                "          <li><a>Longitude <span class='pull-right'>" + v.longitude + "</span></a></li>" +
                "          <li><a>Hora <span class='pull-right'>" + (dtStr ? dtStr : '-') + "</span></a></li>" +
                "          <li><a>Velocidade <span class='pull-right'>" + (v.speed ? v.speed : '-') + "</span></a></li>" +
                "          <li><a>Ignição <span class='pull-right'>" + (v.vehicleIgnition ? v.vehicleIgnition : '-') + "</span></a></li>" +
                "          <li><a>Precisão <span class='pull-right'>Entre 10 e 50 metros</span></a></li>" +
                "      </ul>" +
                "   </div>" +
                " </div>" +
                "</div>"
                );
        }

        // specify popup options
        var customOptions = { 'className' : 'leafPopup', autoPan: true, keepInView: true };

        function addVehicleToLayer(v) {
            var marker = L.marker([v.latitude, v.longitude], {icon: vehicleIcon, id: v.vehicleId});
            marker.bindTooltip(v.vehiclePlate);
            marker.bindPopup(vehicleTooltipTemplate(v), customOptions);

            vehicleMarkers.addLayer(marker);
            return marker;
        }

        $scope.$on("onVehicleClickEvent", function(evt, vehicle){
            myMap.setView([vehicle.latitude, vehicle.longitude], 17);
            var marker = addVehicleToLayer(vehicle);
            marker.openPopup();
        });

        $scope.$on('onItineraryClickEvent', function(evt, data) {
            selectItinerary(data.data, data.fitBounds);
        });


        $scope.$on('showPositions', function(evt, data) {

            engineAndSpeedLayerGroup = L.featureGroup();

            var latLongs = [];

            angular.forEach(data.positions, function(position) {
                if (position.vehicleIgnition == 0) {
                    var dt = moment(position.deviceTime);
                    var str = 'Engine OFF: ' + dt.utc().format('HH:mm');
                    engineAndSpeedLayerGroup.addLayer(L.marker([position.latitude, position.longitude], {icon: redMarker}).bindTooltip(str));
                }
                if (position.speed > 111) {
                    engineAndSpeedLayerGroup.addLayer(L.marker([position.latitude, position.longitude], {icon: speedMarker})
                        .bindTooltip('Speed: ' + parseFloat(position.speed).toFixed(0) + ' km/h'));
                }
                latLongs.push([position.latitude, position.longitude]);
            });
            latLongs.reverse();

            var lineColor = '#0052cc';
            polyline = L.polyline(latLongs, {color: lineColor});
            // --- Simple arrow ---
            var polylineDecorator = L.polylineDecorator(polyline, {
                patterns: [
                    {offset: 25, repeat: 100, symbol: L.Symbol.arrowHead({pixelSize: 10, pathOptions: {color: lineColor, fillOpacity: 1, weight: 0}})
                    },
                    { offset: 12, repeat: 25, symbol: L.Symbol.dash({pixelSize: 10, pathOptions: {color: lineColor, weight: 1}}) },
                    { offset: 0, repeat: 25, symbol: L.Symbol.dash({pixelSize: 0, pathOptions: {color: lineColor} }) }
                ]
            });
            engineAndSpeedLayerGroup.addLayer(polylineDecorator);
            myMap.addLayer(engineAndSpeedLayerGroup);

            if (data.fitBounds && latLongs.length > 1) {
                //myMap.fitBounds(polyline.getBounds(), {padding: [50, 50]});
                vm.fitBounds();
            }
        });

        vm.onTaskClicked = function (marker) {
//            $state.go('home.itineraries.task', {id: marker.model.id});

            var taskId =  marker.target.options.id;

            $uibModal.open({
                templateUrl: 'app/entities/task/task-dialog-detail.html',
                controller: 'TaskDialogDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['Task', function(Task) {
                        return Task.get({id : taskId}).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('task');
                        $translatePartialLoader.addPart('taskType');
                        $translatePartialLoader.addPart('taskStatus');
                        $translatePartialLoader.addPart('taskEvent');
                        $translatePartialLoader.addPart('taskEventType');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function() {
//                    $state.go('^', null, { reload: false });
            }, function() {
//                    $state.go('^');
            });
        }

        vm.fitBounds = function() {
            var bounds = [];
            bounds.push(vehicleMarkers.getBounds());
            bounds.push(polyline.getBounds());
            bounds.push(tasksMarkers.getBounds());
            myMap.fitBounds(bounds, {padding: [50, 50]});
        }

        function selectItinerary(data, fitBounds) {
            vm.tasks = [];

            if (data == undefined) return;

            angular.forEach(data.tasks, function(taskItem) {
                for (var i=0; i < vm.tasks.length; i++) {
                    var existingMarker = vm.tasks[i];
                    //if a marker already exists in the same position as this marker
                    if (existingMarker.latitude == taskItem.latitude && existingMarker.longitude == taskItem.longitude) {
                        //update the position of the coincident marker by applying a small multipler to its coordinates
                        taskItem.latitude = taskItem.latitude + (Math.random() -.5) / 2500;// * (Math.random() * (max - min) + min);
                        taskItem.longitude = taskItem.longitude + (Math.random() -.5) / 2500;// * (Math.random() * (max - min) + min);
                    }
                }

                var icon = 'content/images/i_delivery.png';
                var popupHtml = '';
                switch(taskItem.taskStatus) {
                    case 'COMPLETED':
                        icon = 'content/images/i_delivery_green.png';
                        break;
                    case 'REJECTED': icon = 'content/images/i_delivery_red.png'; break;
                    case 'PARTIALLY_DONE': icon = 'content/images/i_delivery_partial.png'; break;
                    case 'IN_PROGRESS': icon = 'content/images/i_delivering.png'; break;
                }

                var deliveryIcon = L.icon({ iconUrl: icon });
                var marker = L.marker([taskItem.latitude, taskItem.longitude], {icon: deliveryIcon, id: taskItem.id})
                    .bindTooltip(taskItem.name);

                marker.on('click', function(e) {
                    vm.onTaskClicked(e);
                });

                tasksMarkers.addLayer(marker);

            });

            if (data.vehicleLastPosition) {
                addVehicleToLayer(data.vehicleLastPosition);
            }

            myMap.addLayer(tasksMarkers);
            myMap.addLayer(vehicleMarkers);

            if (fitBounds) {
                vm.fitBounds();
            }
        };


    }
})();
