(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('PersonDistanceDialogController', PersonDistanceDialogController);

    PersonDistanceDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PersonDistance', 'Person'];

    function PersonDistanceDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, PersonDistance, Person) {
        var vm = this;

        vm.personDistance = entity;
        vm.clear = clear;
        vm.save = save;
//        vm.people = Person.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.personDistance.id !== null) {
                PersonDistance.update(vm.personDistance, onSaveSuccess, onSaveError);
            } else {
                PersonDistance.save(vm.personDistance, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('fleetApp:personDistanceUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
