(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('ItineraryController', ItineraryController);

    ItineraryController.$inject = ['$scope', '$state', 'Itinerary', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', '$filter',
     'DateUtils'];

    function ItineraryController ($scope, $state, Itinerary, ParseLinks, AlertService, pagingParams, paginationConstants, $filter, DateUtils) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;

        vm.fromDate = DateUtils.convertStringToDate(pagingParams.date);

        loadAll();

        function loadAll () {
            Itinerary.query({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                date: pagingParams.date,
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.itineraries = data;
                vm.page = pagingParams.page;
                vm.date = pagingParams.date;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                date: vm.date
            });
        }

        vm.onDateChange = function () {
            vm.date = $filter('date')(vm.fromDate, 'yyyy-MM-dd');
            transition();
        }
    }
})();
