(function() {
    'use strict';

    var deliverItem = {
        templateUrl: 'app/entities/deliver/deliver-item.component.html',
        controller: DeliverController,
        bindings: {
            task: '='
        }
    };

    angular
        .module('fleetApp')
        .component('deliverItem', deliverItem);

    DeliverController.$inject = ['$scope', 'Invoice', 'AlertService', '$state', '$uibModal', 'Task', 'RefusedReason'];

    function DeliverController($scope, Invoice, AlertService, $state, $uibModal, Task, RefusedReason) {
        var vm = this;

        RefusedReason.all({}, function(data){
            vm.refusedReasons = data;
        }, onError);

        $scope.status = {
            isCustomHeaderOpen: false,
            isFirstOpen: true,
            isFirstDisabled: false
        };

        vm.invoiceDetailOpen = false

        vm.searchService = function (task, serviceNumber) {
            if (serviceNumber != undefined && task.servicesNumber.indexOf(serviceNumber) === -1) {
                Invoice.personbyservicenumber({serviceNumber: serviceNumber}, function(data){
                    if (task.personId != undefined && task.personId != data.id) {
                        AlertService.error("A ordem de serviço " + serviceNumber + " não pertence ao cliente " + task.personName)
                    } else {
                        task.servicesNumber.push(serviceNumber);
                        task.personId = data.id;
                        task.personName = data.name;
                    }
                }, onError);

            }
        }

        vm.searchByOrderNumber = function(task, orderNumber) {
            if (orderNumber != undefined && task.ordersNumber.indexOf(orderNumber) === -1) {
                 Invoice.personbyordernumber({orderNumber: orderNumber}, function(data){
                     if (task.personId != undefined && task.personId != data.id) {
                         AlertService.error("O pedido " + orderNumber + " não pertence ao cliente " + task.personName)
                     } else {
                         task.ordersNumber.push(orderNumber);
                         task.personId = data.id;
                         task.personName = data.name;
                     }
                 }, onError);
             }
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        vm.removeService = function(task, serviceNumber, indexjs) {
            task.servicesNumber.splice(task.servicesNumber.indexOf(serviceNumber), 1);
        }

        vm.removeOrder = function(task, orderNumber, indexjs) {
            task.ordersNumber.splice(task.ordersNumber.indexOf(orderNumber), 1);
        }

        vm.showInvoices = function(task) {
//            Task.get({id : task.id}, function(data) {
//                if (data) {
//                    vm.task.itens = data.itens;
//                }
//            }, onError);
        }

        vm.taskRejectDetail = function() {
//            console.log(vm.task);
//            $uibModal.open({
//                    templateUrl: 'app/entities/itinerary/itinerary-dialog.html',
//                    controller: 'ItineraryDialogController',
//                    controllerAs: 'vm',
//                    backdrop: 'static',
//                    size: 'lg',
//                    resolve: {
//                        entity: vm.task
//                        },
//                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
//                            $translatePartialLoader.addPart('itinerary');
//                            $translatePartialLoader.addPart('task');
//                            $translatePartialLoader.addPart('taskStatus');
//                            $translatePartialLoader.addPart('visit');
//                            $translatePartialLoader.addPart('global');
//                            return $translate.refresh();
//                        }]
//                }).result.then(function() {
////                    $state.go('^', null, { reload: true });
//                }, function() {
////                    $state.go('^');
//                });
        }

    }
})();
