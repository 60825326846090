(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('MessagesDialogController', MessageDialogController);

    MessageDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Message', 'Device'];

    function MessageDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Message, Device) {
        var vm = this;

        vm.messages = entity;
        vm.clear = clear;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();
