(function() {
    'use strict';
    angular
        .module('fleetApp')
        .factory('Itinerary', Itinerary);

    Itinerary.$inject = ['$resource', 'DateUtils', 'ImageService'];

    function Itinerary ($resource, DateUtils, ImageService) {
        var resourceUrl =  'api/itineraries/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true,
                transformResponse: function (data, headersGetter, status) {
                    if (data) {
                        data = angular.fromJson(data);
                        if (status == 200) {
                            var models = [];
                            angular.forEach(data, function(item) {
                                item.creationDate = DateUtils.convertDateTimeFromServer(item.creationDate);
                                item.plannedDate = DateUtils.convertDateTimeFromServer(item.plannedDate);
                                item.startTime = DateUtils.convertDateTimeFromServer(item.startTime);
                                item.endTime = DateUtils.convertDateTimeFromServer(item.endTime);
                                item.plannedDate = DateUtils.convertDateTimeFromServer(item.plannedDate);
                                models.push(item);
                            });
                            return models;
                        }
                    }
                    return data;
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.creationDate = DateUtils.convertDateTimeFromServer(data.creationDate);
                        data.plannedDate = DateUtils.convertDateTimeFromServer(data.plannedDate);
                        data.startTime = DateUtils.convertDateTimeFromServer(data.startTime);
                        data.endTime = DateUtils.convertDateTimeFromServer(data.endTime);
                        angular.forEach(data.tasks, function(task) {
                            task.firstTimeIn = DateUtils.convertDateTimeFromServer(task.firstTimeIn);
                            task.lastTimeOut = DateUtils.convertDateTimeFromServer(task.lastTimeOut);
                        });
                    }
                    return data;
                }
            },
            'dashboardlist': {
                method: 'GET', isArray: true,
                url: 'api/itineraries/dashboardlist',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.positionTime = DateUtils.convertDateTimeFromServer(data.positionTime);
                    }
                    return data;
                }
            },
            'graphlist': {
                method: 'GET', isArray: true,
                url: 'api/itineraries/graphlist',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        angular.forEach(data, function(groupItem) {
                            if (groupItem) {
                                groupItem.vehiclePhotoUrl = ImageService.thumbnail(groupItem.vehiclePhotoUrl);
                                angular.forEach(groupItem.itineraries, function(item) {
                                    item.startTime = DateUtils.convertDateTimeFromServer(item.startTime);
                                    item.endTime   = DateUtils.convertDateTimeFromServer(item.endTime);
                                    item.spentTime = DateUtils.convertMinutesToDisplay(item.spentTime);
                                    angular.forEach(item.tasks, function(task) {
                                        task.spentTime = DateUtils.convertMinutesToDisplay(task.spentTime);
                                    });
                                });
                            }
                        });
                    }
                    return data;
                }
            },
            'geojson': {
                method: 'GET',
                url: '/api/itineraries/geojson/:id'
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    return angular.toJson(data);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    return angular.toJson(data);
                }
            }
        });
    }
})();
