(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('PersonDialogController', PersonDialogController);

    PersonDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'entity', 'Person', 'DataUtils'];

    function PersonDialogController ($timeout, $scope, $uibModalInstance, entity, Person, DataUtils) {
        var vm = this;

        vm.person = entity;
        vm.clear = clear;
        vm.save = save;

        var map;
        var marker;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();

            initialize();
            initCloudnary();
        });

        function initCloudnary() {
            // eslint-disable-next-line no-undef
            var myWidget = cloudinary.createUploadWidget(
                {
                    cloudName: "dvzlcxrvw",
                    multiple: false,
                    defaultSource: "local",
                    uploadPreset: "clientes"
                },
                function(error, result) {
                    if (!error && result && result.event === "success") {
                        vm.person.photoUrl = result.info.url;
                        $scope.$apply();
                    }
                }
            );

            // eslint-disable-next-line angular/document-service
            document.getElementById("upload_widget").addEventListener(
                "click",
                function() {
                    myWidget.open();
                },
                false
            );
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.person.id !== null) {
                Person.update(vm.person, onSaveSuccess, onSaveError);
            } else {
                vm.person.companyId = 1; // will be changed at backend
                Person.save(vm.person, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('fleetApp:personUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.setPhoto = function ($file, person) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        person.photo = base64Data;
                        person.photoContentType = $file.type;
                    });
                });
            }
        };

        function initialize() {

            var latLng = new google.maps.LatLng(-10.8665433,10.1956316);
            map = new google.maps.Map(document.getElementById('map'), { center: latLng, zoom: 3 });

            if (vm.person.address.latitude != undefined) {
                latLng = new google.maps.LatLng(vm.person.address.latitude, vm.person.address.longitude);
                map.setCenter(latLng);
                map.setZoom(15);
                createMarker(vm.person.name, latLng);
            } else {
                // Try HTML5 geolocation.
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(function(position) {

                        latLng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                        map.setCenter(latLng);
                        map.setZoom(15);
                        createMarker(vm.person.name, latLng);

                    }, function() {
                        handleLocationError(true, map.getCenter());
                    });
                } else {
                    // Browser doesn't support Geolocation
                    handleLocationError(false, map.getCenter());
                }
            }

            addSearchAddressBox();
        }

        function handleLocationError(browserHasGeolocation, pos) {
            var title = browserHasGeolocation ?
                                  'Error: The Geolocation service failed.' :
                                  'Error: Your browser doesn\'t support geolocation.';
             createMarker(title, pos);
        }

        function createMarker(title, location) {

            marker = new google.maps.Marker({position: location, title: title, map: map, draggable:true});
            var infoWindow = new google.maps.InfoWindow({ content: title });

            marker.addListener('click', function() {
                infoWindow.open(map, marker);
            });

            marker.addListener('drag', handleEvent);
            marker.addListener('dragend', handleEvent);
        }

        function handleEvent(event) {
            vm.person.address.latitude = event.latLng.lat();
            vm.person.address.longitude = event.latLng.lng();
            $scope.$apply();
        }

        vm.saveAndUpdateTasks = function() {
            Person.saveAndUpdateTasks(vm.person, onSaveSuccess, onSaveError);
        }

        function addSearchAddressBox() {


            var input = document.getElementById('pac-input');
            var searchBox = new google.maps.places.SearchBox(input);
            map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

            map.addListener('bounds_changed', function() {
              searchBox.setBounds(map.getBounds());
            });

            var markers = [];
            // Listen for the event fired when the user selects a prediction and retrieve
            // more details for that place.
            searchBox.addListener('places_changed', function() {
              var places = searchBox.getPlaces();

              if (places.length == 0) {
                return;
              }

              // Clear out the old markers.
              markers.forEach(function(marker) {
                marker.setMap(null);
              });
              markers = [];

              // For each place, get the icon, name and location.
              var bounds = new google.maps.LatLngBounds();
              places.forEach(function(place) {
                if (!place.geometry) {
                  console.log("Returned place contains no geometry");
                  return;
                }
                var icon = {
                  url: place.icon,
                  size: new google.maps.Size(71, 71),
                  origin: new google.maps.Point(0, 0),
                  anchor: new google.maps.Point(17, 34),
                  scaledSize: new google.maps.Size(25, 25)
                };

                // Create a marker for each place.
                markers.push(new google.maps.Marker({
                  map: map,
                  icon: icon,
                  title: place.name,
                  position: place.geometry.location
                }));

                // UPDATE MARKER LOCATION
                marker.setPosition(place.geometry.location);
                new google.maps.event.trigger( marker, 'drag' );


                if (place.geometry.viewport) {
                  // Only geocodes have viewport.
                  bounds.union(place.geometry.viewport);
                } else {
                  bounds.extend(place.geometry.location);
                }
              });
              map.fitBounds(bounds);
            });
        }

    }
})();
