(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('InvoiceItemsDialogController', InvoiceItemsDialogController);

    InvoiceItemsDialogController.$inject = ['$scope', '$state', 'entity', '$uibModalInstance'];

    function InvoiceItemsDialogController ($scope, $state, entity, $uibModalInstance) {
        var vm = this;

        vm.invoiceItems = entity;
        vm.clear = clear;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
