(function() {
    'use strict';

    angular
        .module('fleetApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('report', {
            parent: 'entity',
            url: '/report?page&sort&search&inidate&enddate&secondExternalId',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'fleetApp.report.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/report/reports.html',
                    controller: 'ReportController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null,
                inidate: null,
                enddate: null,
                product: null,
                person: null,
                secondExternalId: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', '$filter', function ($stateParams, PaginationUtil, $filter) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                        inidate: $stateParams.inidate == null ? $filter('date')(new Date(), "yyyy-MM-dd") : $stateParams.inidate,
                        enddate: $stateParams.enddate == null ? $filter('date')(new Date(), "yyyy-MM-dd") : $stateParams.enddate,
                        product: $stateParams.product,
                        person: $stateParams.person,
                        secondExternalId: $stateParams.secondExternalId
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('report');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('report.deliveredProduct', {
            parent: 'entity',
            url: '/report/delivered-product?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'fleetApp.report.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/report/delivered-product.html',
                    controller: 'ReportController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('report');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('report.products-by-task', {
            parent: 'report',
            url: '/{id}/products',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/invoice-item/invoice-items-dialog.html',
                    controller: 'InvoiceItemsDialogController',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                        entity: ['InvoiceItem', function(InvoiceItem) {
                            return InvoiceItem.getInvoiceItens({taskid : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('invoiceItem');
                    return $translate.refresh();
                }]
            }
        })
        .state('report.payment', {
            parent: 'app',
            url: '/report-payment',
            data: {
                authorities: ['ACCESS_REPORTS']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/report/payment-report.html',
                    controller: 'PaymentReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('report');
                    $translatePartialLoader.addPart('vehiclePayment');
                    $translatePartialLoader.addPart('vehiclePaymentItem');
                    return $translate.refresh();
                }]
            }
        })
        .state('report.time', {
            parent: 'app',
            url: '/report-time',
            data: {
                authorities: ['ACCESS_REPORTS']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/report/time-report.html',
                    controller: 'TimeReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('report');
                    return $translate.refresh();
                }]
            }
        });
    }

})();
