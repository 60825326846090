(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('BankDetailController', BankDetailController);

    BankDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Bank'];

    function BankDetailController($scope, $rootScope, $stateParams, entity, Bank) {
        var vm = this;

        vm.bank = entity;

        var unsubscribe = $rootScope.$on('fleetApp:bankUpdate', function(event, result) {
            vm.bank = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
