(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('ExpenseController', ExpenseController);

    ExpenseController.$inject = ['$scope', '$state', 'DataUtils', 'Expense', 'ParseLinks', 'AlertService', 'pagingParams',
        'paginationConstants', 'DateUtils', '$filter'];

    function ExpenseController ($scope, $state, DataUtils, Expense, ParseLinks, AlertService, pagingParams, paginationConstants, DateUtils, $filter) {
        var vm = this;

        var dateFormat = 'yyyy-MM-dd';

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;

        vm.fromDate = DateUtils.convertStringToDate(pagingParams.from);
        vm.toDate = DateUtils.convertStringToDate(pagingParams.to);
        vm.vehiclePlate = pagingParams.vehiclePlate;
        vm.vehicleId = pagingParams.vehicleId;

        vm.loadAll = function() {
            Expense.query({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                from: $filter('date')(vm.fromDate, dateFormat),
                to: $filter('date')(vm.toDate, dateFormat),
                vehiclePlate: pagingParams.vehiclePlate,
                vehicleId: pagingParams.vehicleId
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.expenses = data;
                vm.page = pagingParams.page;
                vm.from = pagingParams.from;
                vm.to = pagingParams.to;
                vm.plate = pagingParams.plate;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                from: $filter('date')(vm.fromDate, dateFormat),
                to: $filter('date')(vm.toDate, dateFormat),
                vehicleId: vm.vehicleId,
                vehiclePlate: vm.vehiclePlate
            });
        }

        vm.onDateChange = function (key) {
            transition();
        }

        $scope.$watch('vm.vehicleId', function (vehicleId) {
            transition();
        });

        vm.loadAll();
    }
})();
