(function() {
    "use strict";

    angular
        .module("fleetApp")
        .controller(
            "ItineraryMapDialogController",
            ItineraryMapDialogController
        );

    ItineraryMapDialogController.$inject = [
        "$uibModalInstance",
        "geojson",
        "positions",
        "$timeout",
        "$rootScope"
    ];

    function ItineraryMapDialogController($uibModalInstance, geojson, positions, $timeout, $rootScope) {
        var vm = this;

        vm.clear = clear;
        vm.close = close;

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }

        function close(id) {
            $uibModalInstance.close(true);
        }

        function invalidateSize() {
            $timeout(function() {
                $("#mapid").height($(window).height() - 200);
                // myMap.invalidateSize();
                $rootScope.$broadcast("invalidateMapSize");

                $rootScope.$broadcast('clearMap');

                $rootScope.$broadcast("onItineraryClickEvent", {data: geojson, fitBounds: true});
                $rootScope.$broadcast("showPositions", {positions: positions, fitBounds: true});
            }, 200);
        }

        invalidateSize();
    }
})();
