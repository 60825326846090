(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('VehiclePaymentController', VehiclePaymentController);

    VehiclePaymentController.$inject = ['$scope', '$state', 'VehiclePayment', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants',
    'DateUtils', '$filter', '$http'];

    function VehiclePaymentController ($scope, $state, VehiclePayment, ParseLinks, AlertService, pagingParams, paginationConstants, DateUtils,
    $filter, $http) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.loadAll = loadAll;

        vm.startDate = DateUtils.convertLocalDateFromServer(pagingParams.start);
        vm.endDate = DateUtils.convertLocalDateFromServer(pagingParams.end);

        vm.vehicleId = pagingParams.vehicleId;
        vm.vehiclePlate = pagingParams.plate;

        vm.datePickerOpenStatus = {};

        loadAll();

        function loadAll () {
            VehiclePayment.query({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                startDate: DateUtils.convertLocalDateToServer(pagingParams.start),
                endDate: DateUtils.convertLocalDateToServer(pagingParams.end),
                vehicleId: pagingParams.vehicleId
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.vehiclePayments = data;
                vm.page = pagingParams.page;
                vm.vehicleId = pagingParams.vehicleId;
                vm.vehiclePlate = pagingParams.plate;
            }
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        vm.search = function () {
            transition();
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                start: DateUtils.convertLocalDateToServer(vm.startDate),
                end: DateUtils.convertLocalDateToServer(vm.endDate),
                vehicleId: vm.vehicleId,
                plate: vm.vehiclePlate
            });
        }

        vm.fortnight = function (status) {
            var dt = moment().startOf('month');
            if (status === 'first') {
                vm.startDate = dt.toDate();
                vm.endDate = dt.clone().add(14, 'd').toDate();
            } else {
                vm.startDate = dt.clone().add(15, 'd').toDate();
                vm.endDate = dt.clone().endOf('month').toDate();
            }
        }

        vm.reportTotal = function() {
            vm.startDate.setHours(0,0,0,0);
            vm.endDate.setHours(0,0,0,0);
            var dto = {startDate: vm.startDate, endDate: vm.endDate };
            $http.post('api/vehicle-payments/report-total/', dto, { responseType: 'arraybuffer' }).then(function (response) {
                var blob = new Blob([response.data], {type: "application/vnd.ms-excel"});
                saveAs(blob, "ReportTotal.xls");
            }, onError);
        }

        vm.report = function(id) {
            $http.get('api/vehicle-payments/report/' + id, { responseType: 'arraybuffer' }).then(function (response) {
                var blob = new Blob([response.data], {type: "application/vnd.ms-excel"});
                saveAs(blob, "Report.xls");
            }, onError);
        }

        vm.datePickerOpenStatus.date = false;

        vm.openCalendar = function (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
