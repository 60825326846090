(function() {
    'use strict';

    var personTypeahead = {
        template: '<div class="form-group">' +
            '<label class="control-label" translate="{{$ctrl.label}}" for="field_person">person</label>' +
            '<div class="input-group">' +
            ' <input type="text" ng-model="$ctrl.modelLabel" placeholder="{{ $ctrl.label | translate }}" ' +
            '  uib-typeahead="address as address.name for address in getLocation($viewValue)" ' +
            '  typeahead-on-select="onSelect($item, $model, $label)" ' +
            '  typeahead-loading="loadingLocations" typeahead-no-results="noResults" class="form-control">' +
            ' <span class="input-group-btn"> ' +
            '   <button type="button" class="btn btn-default" ng-click="$ctrl.clear()"><i class="glyphicon glyphicon-remove"></i></button> ' +
            ' </span>'+
            '</div>' +
            '<i ng-show="loadingLocations" class="glyphicon glyphicon-refresh"></i>' +
            ' <div ng-show="noResults">' +
            '   <i class="glyphicon glyphicon-remove"></i> <span translate="error.notFound"></span>' +
            ' </div>' +
            '</div>',
        controller: PersonTypeaheadController,
        bindings: {
            modelId: '=',
            modelLabel: '=',
            label: '<'
        }
    };

    angular
        .module('fleetApp')
        .component('personTypeahead', personTypeahead);

    PersonTypeaheadController.$inject = ['$scope', 'Person', '$http', 'paginationConstants'];

    function PersonTypeaheadController($scope, Person, $http, paginationConstants) {
        var vm = this;

        vm.$onInit = function () {
        };

        $scope.onSelect = function(item, model, label) {
            vm.modelLabel = model.name;
            vm.modelId = model.id;
        }

        $scope.getLocation = function(val) {
            return Person.findByName({
                sort: '',
                page: 0,
                size: paginationConstants.itemsPerPageTypeahead,
                name: val}).$promise;
        };

        vm.clear = function() {
            vm.modelId = undefined;
            vm.modelLabel = undefined;
            $scope.noResults = false;
        }
    }
})();
