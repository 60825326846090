(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('MessageController', MessageController);

    MessageController.$inject = ['$filter', '$scope', '$state', 'Message', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'DateUtils'];

    function MessageController ($filter, $scope, $state, Message, ParseLinks, AlertService, pagingParams, paginationConstants, DateUtils) {
        var vm = this;

        var dateFormat = 'yyyy-MM-dd';

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.loadAll = loadAll;
        vm.job = job;
        vm.jobAllMessages = jobAllMessages;

        vm.fromDate = DateUtils.convertStringToDate(pagingParams.date);

        loadAll();

        function loadAll () {
            Message.query({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                fromDate: pagingParams.date,
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.messages = data;
                vm.page = pagingParams.page;
                vm.date = pagingParams.date;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                date: vm.date
            });
        }

        function jobAllMessages() {
            Message.jobAllMessages({fromDate: vm.date}, onSuccess, onError);
        }

        function job(id) {
            Message.job({id: id}, onSuccess, onError);
        }

        function onSuccess(data, headers) {
            $scope.$emit('fleetApp:messageUpdate', data);
            loadAll();
        }
        function onError(error) {
            AlertService.error(error.data.message);
        }

        vm.onDateChange = function () {
            vm.date = $filter('date')(vm.fromDate, dateFormat);
            transition();
        }
    }
})();
