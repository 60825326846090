// Binding select to a non-string value via ngModel parsing / formatting
// https://code.angularjs.org/1.4.6/docs/api/ng/directive/select#binding-select-to-a-non-string-value-via-ngmodel-parsing-formatting

(function() {
    'use strict';

    angular
        .module('fleetApp')
        .directive('convertToNumber', convertToNumber);

    function convertToNumber() {
        var directive = {
            require: 'ngModel',
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element, attrs, ngModel) {
            ngModel.$parsers.push(function(val) {
                return parseInt(val, 10);
            });
            ngModel.$formatters.push(function(val) {
                return '' + val;
            });
        };
    }
})();

