(function() {
    'use strict';

    angular
        .module('fleetApp')
        .factory('DateUtils', DateUtils);

    DateUtils.$inject = ['$filter'];

    function DateUtils ($filter) {

        var service = {
            convertDateTimeFromServer : convertDateTimeFromServer,
            convertLocalDateFromServer : convertLocalDateFromServer,
            convertLocalDateToServer : convertLocalDateToServer,
            dateformat : dateformat,
            convertMinutesToDisplay : convertMinutesToDisplay,
            convertStringToDate : convertStringToDate,
            newDateString : newDateString,
            utcNow : utcNow,
            convertLocalDateTimeToServer: convertLocalDateTimeToServer,
            convertStringToDateMomentMMDDYYYY: convertStringToDateMomentMMDDYYYY
        };

        return service;

        /**
        * Retorna um now na timezone 0
        */
        function utcNow () {
            return new Date();
        }

        function convertDateTimeFromServer (date) {
            if (date) {
                return new Date(date);

            } else {
                return null;
            }
        }

        function convertLocalDateFromServer (date) {
            if (date) {
                var dateString = date.split('-');
                return new Date(dateString[0], dateString[1] - 1, dateString[2]);
            }
            return null;
        }

        function convertLocalDateTimeToServer (date) {
             if (date) {
                return $filter('date')(date, 'yyyy-MM-dd\'T\'HH:mm:ss.Z');
            } else {
                return null;
            }
        }

        function convertLocalDateToServer (date) {
            if (date) {
                return $filter('date')(date, 'yyyy-MM-dd');
            } else {
                return null;
            }
        }

        function dateformat () {
            return 'yyyy-MM-dd';
        }

        function newDateString() {
            return $filter('date')(new Date(), "yyyy-MM-dd")
         }

        //http://stackoverflow.com/questions/25470475/angular-js-format-minutes-in-template
        function convertMinutesToDisplay(minutes) {
            if (minutes == undefined) {
                return ''
            }
            var result;
            if(minutes < 60){
                result = (minutes) + 'm';
            }
            else if(minutes % 60==0){
                result = (minutes - minutes % 60)/60 + 'h';
            }
            else{
                 result = ((minutes - minutes % 60)/60 + 'h' + ' ' + minutes % 60 + 'm');
            }
            return result;
        }

         /* deprecated! not working at GMT+0 */
        // Convert '2016-01-20' to a date object
        function convertStringToDate(str) {
            if (!str) { return null; }
            var pattern = /(\d{4})(\d{2})(\d{2})/;
            var dat = new Date(str.replace(pattern, '$1-$2-$3'));
            dat.setDate(dat.getDate() + 1);
            return dat;
        }

        function convertStringToDateMomentMMDDYYYY(str) {
            return moment(str, "YYYY-MM-DD").toDate();
        }
    }

})();
