(function() {
    'use strict';

    var bankAccountTypeahead = {
        template: '<div class="form-group">' +
            '<label class="control-label" ng-if="!$ctrl.hideTitle" translate="global.menu.entities.bankAccount" for="field_vehicle"> ' +
            '  bankAccount</label> ' +
            '<div class="input-group">' +
            ' <input type="text" ng-model="$ctrl.modelLabel" placeholder="{{ \'global.menu.entities.bankAccount\' | translate }}" ' +
            '  typeahead-on-select="onSelect($item, $model, $label)"  ' +
            '  uib-typeahead="address as address.name for address in getEntityData($viewValue)" ' +
            '  typeahead-loading="loadingLocations" typeahead-no-results="noResults" class="form-control">' +
            ' <span class="input-group-btn"> ' +
            '   <button type="button" class="btn btn-default" ng-click="$ctrl.clear()"><i class="glyphicon glyphicon-remove"></i></button> ' +
            ' </span>'+
            '</div>' +
            '<i ng-show="loadingLocations" class="glyphicon glyphicon-refresh"></i>' +
            ' <div ng-show="noResults">' +
            '   <i class="glyphicon glyphicon-remove"></i> <span translate="error.notFound"></span>' +
            ' </div>' +
            '</div>',
        controller: BankAccountTypeaheadController,
        bindings: {
            modelEntity: '=',
            modelId: '=',
            modelLabel: '=',
            hideTitle: '@' // title hide when true
        }
    };

    angular
        .module('fleetApp')
        .component('bankAccountTypeahead', bankAccountTypeahead);

    BankAccountTypeaheadController.$inject = ['$scope', 'BankAccount', '$http', 'paginationConstants'];

    function BankAccountTypeaheadController($scope, BankAccount, $http, paginationConstants) {
        var vm = this;

        vm.$onInit = function () {
        };

        $scope.onSelect = function(item, model, label) {
            vm.modelLabel = model.name;
            vm.modelId = model.id;
        }

        $scope.getEntityData = function(val) {
            return BankAccount.search({
                sort: '',
                page: 0,
                size: paginationConstants.itemsPerPageTypeahead,
                bankName: val}).$promise;
        };

        vm.clear = function() {
            vm.modelLabel = undefined;
            vm.modelId = undefined;
            $scope.noResults = false;
        }
    }
})();
