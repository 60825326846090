(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('PositionDialogController', PositionDialogController);

    PositionDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Position', 'Vehicle'];

    function PositionDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Position, Vehicle) {
        var vm = this;

        vm.position = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.vehicles = Vehicle.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.position.id !== null) {
                Position.update(vm.position, onSaveSuccess, onSaveError);
            } else {
                Position.save(vm.position, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('fleetApp:positionUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.serverTime = false;
        vm.datePickerOpenStatus.deviceTime = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
