(function() {
    'use strict';

    angular
        .module('fleetApp')
        .controller('ItineraryDialogController', ItineraryDialogController);

    ItineraryDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Itinerary', 'Invoice',
    'AlertService', 'DateUtils'];

    function ItineraryDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Itinerary, Invoice, AlertService,
        DateUtils) {
        var vm = this;

        vm.itinerary = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.itinerary.id !== null) {
                Itinerary.update(vm.itinerary, onSaveSuccess, onSaveError);
            } else {
                Itinerary.save(vm.itinerary, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('fleetApp:itineraryUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError (error) {
            vm.isSaving = false;
            AlertService.error(error.data.message);
        }

        vm.isOpen = function(id) {
            return vm.datePickerOpenStatus[id];
        }

        vm.datePickerOpenStatus.creationDate = false;
        vm.datePickerOpenStatus.plannedDate = false;
        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false;
        vm.datePickerOpenStatus.firstTimeIn = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.removeTask = function(index) {
            vm.itinerary.tasks.splice(index, 1);
        }

        vm.addTask = function(taskType) {
            var dt = DateUtils.utcNow();
            vm.itinerary.tasks.push({taskType: taskType,
                firstTimeIn: dt,
                lastTimeOut: dt,
                servicesNumber: [],
                ordersNumber: []});
        }

        // time picker
        vm.timepickerOptions = {
           readonlyInput: false,
           showMeridian: false
        };

    }
})();
