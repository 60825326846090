(function(){
    'use strict';

    angular
        .module('fleetApp')
        .factory('ImageService', ImageService);

    function ImageService () {

        var service = {
            thumbnail : thumbnail,
            image300: image300
        };

        return service;

        function thumbnail (url) {
            if (url) {
                return url.replace('image/upload/', 'image/upload/t_media_lib_thumb/');
            }
            return null;
        }

        function image300 (url) {
            if (url) {
                return url.replace('image/upload/', 'image/upload/c_scale,w_300/');
            }
            return null;
        }
    }
})();
